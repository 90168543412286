import { Auth } from 'aws-amplify'
import { api } from '../../infra/http/remote'
import { getUser, setUser } from '../utils/storage/user'
import { getSettings, setSettings } from '../utils/storage/settings'
import { outdatedCache } from '../utils/storage/date'
import { UsersHelper } from '../helpers/users-helper/users-helper'

export const makeAuth = async () => {
  try {
    const auth = await Auth.currentSession()
    const { email } = auth.getIdToken().decodePayload()
    const lastUpdated = await outdatedCache()
    const user = getUser()

    if (
      lastUpdated ||
      !user ||
      ('user' in user && user.user.email !== email) ||
      ('info' in user && !user.info.hasCustomerFolders)
    ) {
      const { data: me } = await api.get('/me')

      const user = UsersHelper.run(me).build()
      await setUser(user)
    }

    if (lastUpdated || !getSettings()) {
      const settingsResponse = await api.get('/settings')
      const settings = settingsResponse.data['hydra:member']
      await setSettings(settings)
    }
  } catch (e) {
    throw new Error(e.message)
  }
}
