import { RemoteCommentsContent } from '../../../data/usecases/contents/remote-comments'
import { RemoteNetwork } from '../../../data/usecases/networks/remote-networks'
import { RemoteTodasJuntas } from '../../../data/usecases/todasjuntas/remote-todasjuntas'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makePosts = () => {
  return new RemoteNetwork('/posts', makeAxiosHttpClient())
}

export const makePostsInternal = (id) => {
  return new RemoteTodasJuntas(`/posts/${id}`, makeAxiosHttpClient())
}

export const makePostsComments = (id) => {
  return new RemoteNetwork(`/posts/${id}/comments?&exists[parent]=false&order[user.specialist]=DESC&order[createdAt]=DESC`, makeAxiosHttpClient())
}

export const makePostsSubComments = (commentId, id) => {
  return new RemoteNetwork(`/posts/${commentId}/comments/${id}/childrens?order[user.specialist]=DESC&order[createdAt]=DESC`, makeAxiosHttpClient())
}

export const makeTodasJuntasComments = () => {
  return new RemoteCommentsContent('/comments', makeAxiosHttpClient())
}
