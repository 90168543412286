import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteLoadContents {
  #url
  #httpClient

  constructor (url, httpClient) {
    this.#url = url
    this.#httpClient = httpClient
  }

  async load (params) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'get',
      params: {
        ...params
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return {
        list: httpResponse.body['hydra:member'] || httpResponse.body.contents || [],
        total: httpResponse.body['hydra:totalItems']
      }
      default: throw new UnexpectedError()
    }
  }
}
