export const getSource = (url) => {
  let source = ''

  if (url.includes('facebook')) {
    source = 'facebook'
  } else if (url.includes('todasgroup.com.br')) {
    source = 'site - todasgroup.com.br'
  } else if (url.includes('google')) {
    source = 'google'
  } else if (url.includes('google')) {
    source = 'google'
  } else if (url.includes('linkedin')) {
    source = 'linkedin'
  } else if (url.includes('linktree') || url.includes('linktr.ee')) {
    source = 'linktree'
  } else if (url.includes('Twitter')) {
    source = 'google'
  } else if (url.includes('instagram')) {
    source = 'instagram'
  } else if (url === '') {
    source = 'direct'
  }

  return source
}
