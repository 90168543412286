import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const LocationIcon = (props) => {
  return (
    <LocationStyled {...props}>
      <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.47519 20C8.27099 20.0005 8.06934 19.9546 7.88548 19.8658C7.70163 19.7769 7.54037 19.6474 7.41389 19.4871L4.70077 16.0666C3.03689 15.2389 1.69117 13.8872 0.870727 12.2198C0.0502865 10.5523 -0.199389 8.66138 0.160131 6.8381C0.519651 5.01483 1.46844 3.36024 2.86045 2.12902C4.25247 0.897809 6.01054 0.15821 7.86408 0.0240702C9.027 -0.0641228 10.1955 0.0906351 11.2954 0.478503C12.3953 0.866371 13.4024 1.47886 14.2528 2.27703C15.2547 3.21086 16.0155 4.3736 16.4699 5.6657C16.9243 6.95781 17.0588 8.34078 16.8619 9.69623C16.665 11.0517 16.1426 12.3392 15.3394 13.4486C14.5361 14.5581 13.476 15.4563 12.2497 16.0665L9.53659 19.4871C9.4101 19.6474 9.24883 19.7769 9.06495 19.8658C8.88107 19.9546 8.67941 20.0005 8.47519 20ZM8.48081 1.08855C8.30144 1.08855 8.12125 1.09488 7.94025 1.10754C6.31497 1.22567 4.77431 1.87771 3.55802 2.96221C2.34173 4.04671 1.51802 5.50283 1.21508 7.10399C0.912135 8.70516 1.14694 10.3616 1.88296 11.8154C2.61898 13.2693 3.81494 14.4391 5.28472 15.1429C5.35898 15.1785 5.42416 15.2305 5.47534 15.2951L8.26484 18.8121C8.28998 18.8438 8.32196 18.8694 8.35839 18.887C8.39483 18.9046 8.43477 18.9138 8.47524 18.9138C8.51571 18.9138 8.55566 18.9046 8.59209 18.887C8.62853 18.8694 8.66051 18.8438 8.68564 18.8121L11.4751 15.2951C11.5263 15.2305 11.5915 15.1785 11.6658 15.1429C12.7513 14.623 13.6934 13.8458 14.4102 12.879C15.127 11.9121 15.5968 10.7848 15.7788 9.59509C15.9608 8.40533 15.8494 7.18914 15.4543 6.05223C15.0593 4.91532 14.3925 3.89211 13.512 3.07154C12.1497 1.79232 10.3496 1.08282 8.48081 1.08855Z" fill="#AE7576" />
        <path d="M8.47529 12.9548C7.63502 12.9548 6.81362 12.7057 6.11496 12.2388C5.4163 11.772 4.87176 11.1085 4.55021 10.3322C4.22865 9.55588 4.14451 8.70165 4.30844 7.87753C4.47237 7.05341 4.877 6.2964 5.47116 5.70224C6.06532 5.10808 6.82232 4.70345 7.64645 4.53952C8.47057 4.37559 9.3248 4.45973 10.1011 4.78128C10.8774 5.10284 11.5409 5.64738 12.0078 6.34604C12.4746 7.0447 12.7238 7.8661 12.7238 8.70637C12.7225 9.83274 12.2745 10.9126 11.478 11.7091C10.6815 12.5055 9.60166 12.9536 8.47529 12.9548ZM8.47529 5.54411C7.84986 5.54411 7.23847 5.72958 6.71844 6.07705C6.19841 6.42452 5.79309 6.91839 5.55375 7.49622C5.3144 8.07405 5.25178 8.70987 5.37379 9.32329C5.49581 9.9367 5.79698 10.5002 6.23923 10.9424C6.68148 11.3847 7.24494 11.6858 7.85836 11.8079C8.47178 11.9299 9.1076 11.8672 9.68543 11.6279C10.2633 11.3886 10.7571 10.9832 11.1046 10.4632C11.4521 9.94319 11.6375 9.3318 11.6375 8.70637C11.6366 7.86798 11.3031 7.06419 10.7103 6.47136C10.1175 5.87853 9.31368 5.54506 8.47529 5.54411Z" fill="#AE7576" />
      </svg>
    </LocationStyled>
  )
}

const LocationStyled = styled(IconStyled)``
