import React from 'react'
import styled from 'styled-components'
import { BsLinkedin } from 'react-icons/bs'
import COACH from './assets/Coach.jpg'
const BannerCoach = () => {
  return (
    <Container>
      <SubContent>
        {/* <Title>Marcella Puglia </Title> */}
        <HeaderTitle>Marcella Puglia </HeaderTitle>
        <a href="https://www.linkedin.com/in/marcella-puglia" rel="noreferrer" target="_blank">
          <BsLinkedin color="#121214" size={25} style={{ cursor: 'pointer' }} />
        </a>
      </SubContent>

      {/* <NormalTextGray>Para guiar este desenvolvimento sua Coach de carreira será:</NormalTextGray> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <img
          src={COACH}
          style={{ height: 'auto', width: '350px', objectFit: 'cover' }}
          alt="coach de carreira"
        />
      </div>

      <div>
        {/* <TitleCoach>Master Coach</TitleCoach> */}
        <NormalTextGray>
          Especialista em desenvolvimento humano, com mais de 20 anos de experiência em cargos executivos em multinacionais como a Unilever. Possui experiência com times multiculturais de diferentes países, startups do terceiro setor e é especialista em desenvolvimento humano.
        </NormalTextGray>

      </div>
    </Container>
  )
}

export default BannerCoach

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  background-color: #F0F0F0;
  padding:25px;
  
`
const HeaderTitle = styled.h4`
  font-size:25.6px;
  font-weight: 400;
  color: #121214;
  line-height: 25.6px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`
const SubContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
// const Title = styled.h3`
//   font-size: 24px;
//   font-weight: 700;
//   line-height: 24px;
//   color: #121214;
// `

// const TitleCoach = styled.p`
//   font-size:18px;
//   font-weight: bold;
//   color: #e14b2b;
// `
const NormalTextGray = styled.span`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height:24px;
  font-family: "Roboto","Open Sans",sans-serif;`
