import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NumberHelper } from '../../../../helpers/number-helper/number-helper'
import { Button, Flex, Html } from '../../components'
import { customTheme } from '../../../../styles/theme'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import { makeActiveCampaign } from '../../../../../main/usecases/active-campaign/active-campaign'
import { getBrowserInfo, getOperationSystem } from '../../../../helpers/settings-helper/settings-helper'

const CardPlanItem = ({ index, originalPrice, showFullPrice, price, name, description, id, active, installments, free, handleClick, couponMeta }) => {
  const history = useHistory([])
  const [loading, setLoading] = useState(false)

  const goToPlan = async () => {
    setLoading(true)
    ReactGA.event({
      category: 'Plano',
      action: 'Selecionou',
      label: name
    })

    await makeActiveCampaign().post({
      event: `selecionou-plano-${index}`,
      eventData: `NAVEGADOR: ${getBrowserInfo()} | SO: ${getOperationSystem()} | URL: ${window.location.href}`
    })

    history.push({
      pathname: `/comprar-plano/${id}`,
      state: couponMeta
    })
  }

  return (
    <Item className={active ? 'active' : ''}>
      <Header>{name}</Header>
      <Body>
        {showFullPrice &&
          <Flex justifyContent="center" alignItems="center" flexDir="column" mb={10}>
            <Currency
              dangerouslySetInnerHTML={{
                __html: NumberHelper.currency(price).replace(/(R\$)(.*?)/, '<span>$1</span>$2')
              }}
            />
            {installments > 1 && <Installments>ou em até {installments}x</Installments>}
          </Flex>}

        {!showFullPrice &&
          <Flex justifyContent="center" alignItems="center" flexDir="column" mb={10}>
            {originalPrice &&
              <CurrencyMeta>
                De: <span>{installments > 1 && <>{installments}x de</>} {NumberHelper.currency(originalPrice / installments)}</span>
              </CurrencyMeta>}

            <Currency>
              {originalPrice && <span>Por: </span>}
              {installments > 1 && <>{installments}x de </>} {NumberHelper.currency(price / installments)}
            </Currency>
          </Flex>}
        <Html mb={20} html={description} />
        {free
          ? <CustomButton onClick={handleClick}>{loading ? 'Carregando' : 'Escolher'}</CustomButton>
          : <CustomButton onClick={() => goToPlan()}>{loading ? 'Carregando' : 'Escolher'}</CustomButton>}
      </Body>
    </Item>
  )
}

CardPlanItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  originalPrice: PropTypes.number,
  price: PropTypes.number,
  showFullPrice: PropTypes.bool,
  description: PropTypes.string,
  active: PropTypes.bool,
  installments: PropTypes.number,
  free: PropTypes.bool,
  handleClick: PropTypes.func,
  couponMeta: PropTypes.object,
  index: PropTypes.number.isRequired
}

export default CardPlanItem

const Header = styled.div`
  text-align: center;
  line-height: 48px;
  font-size: ${customTheme.fontSizes.md};
`

const Installments = styled.div`
  font-size: ${customTheme.fontSizes.xs};
  margin-right: 5px;
`

const Currency = styled.div`
  line-height: 46.3px;
  font-size: 1.5rem;
  span {
    color: ${customTheme.colors.gray['500']};
  }
`

const Item = styled.div`
  border: 1px solid ${customTheme.colors.bgFooter};
  height: 100%;

  ${Header} {
    background-color: ${customTheme.colors.bgFooter};
    color: ${customTheme.colors.blue['700']};
  }
  
  &.active,
  &:hover {
    border: 1px solid ${customTheme.colors.orange};
    ${Header} {
      background-color: ${customTheme.colors.orange};
      color: ${customTheme.colors.white};
    }
    ${Currency} {
      span {
        color: ${customTheme.colors.pink['600']};
      }
    }
  }
`

const Body = styled(Flex)`
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  height: 89%;
`

const CurrencyMeta = styled.small`
  span {
    text-decoration: line-through;
  }
`

const CustomButton = styled(Button)`
  height: 46px;
  margin-bottom: 10px;
`
