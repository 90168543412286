import React, { useState, useEffect } from 'react'
import { Box, Container, Flex } from '../../components/ui/components'
import { Col, Row, Title } from '../../components/ui/layout'
import Loading from '../../components/loading'
// import ExclusiveContentModal from '../../../components/ui/layout/exclusive-content-modal/exclusive-content-modal'
import HeaderListPage from '../../components/ui/layout/header-list-page/header-list-page'
import { customTheme } from '../../styles/theme'
import { WppIcon } from '../../assets/icons/wpp'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import FaqItem from './faq-item'
import { makeFaq } from '../../../main/usecases/faq/faq-factory'
import { SettingsHelper } from '../../helpers/settings-helper/settings-helper'
import { useTranslation } from 'react-i18next'

const Faq = () => {
  const [data, setData] = useState([])
  // const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(true)
  const [contentShow, setContentShow] = useState('faq')
  const settingsHelper = new SettingsHelper()
  const { t } = useTranslation()

  useEffect(async () => {
    setLoading(true)
    try {
      const contents = await makeFaq().load()
      setData(contents['hydra:member'])
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [location.pathname, contentShow])

  return (
    <Container>
      <Box mx="auto" py={[10, 10, 20, 20, 30]}>
        <HeaderListPage title="Todas Group" setQuery={false} />
        <Title title={contentShow === 'faq' ? t('faq.whatsSpan3') : t('faq.whatsSpan1')} bar />

        <Row>
          <Col xs={12} sm={3} d={['none', 'block', 'block']}>
            <LeftBarStyled>
              <CustomLink
                onClick={() => setContentShow('faq')}
                className="active"
              >
                {/* <Icon name={item.icon} /> */}
                <span>{t('faq.whatsSpan3')}</span>
              </CustomLink>
              <CustomLink
                onClick={() => setContentShow('whatsapp')}
              >
                {/* <Icon name={item.icon} /> */}
                <span>{t('faq.whatsSpan1')}</span>
              </CustomLink>
            </LeftBarStyled>
          </Col>
          <Col xs={12} sm={9}>
            {contentShow === 'faq' &&
              <Row>
                {!data.length && <Message>{t('faq.message')}</Message>}

                {data && data.map((item) => (
                  <FaqItem
                    key={item.id}
                    content={{ ...item, question: t(`faq.question_${item.id}`), answer: t(`faq.answer_${item.id}`) }}
                  />
                ))}
              </Row>}
            {contentShow === 'whatsapp' &&
              <Box px={100}>
                <Text>{t('faq.whatsTxT')}</Text>
                <Box d={['none', 'flex']}>
                  <WppButton target="_blank" href={'https://web.whatsapp.com/send?phone=+5511915753803&text=Olá, tudo bem?\nComo o *Todas Group* pode me ajudar?'}>
                    <span>{t('faq.whatsSpan2')}</span>
                    <WppIcon />
                  </WppButton>
                </Box>

                <Box target="_blank" d={['flex', 'none']}>
                  <WppButton href={`https://api.whatsapp.com/send?phone=${settingsHelper.loadAppContactPhone()}&text=Olá, tudo bem?\nComo o *Todas Group* pode me ajudar?`}>
                    <span>{t('faq.whatsSpan2')}</span>
                    <WppIcon />
                  </WppButton>
                </Box>
              </Box>}
          </Col>
        </Row>

        {loading &&
          <Flex w="100%" justifyContent="center"><Loading /></Flex>}
      </Box>
    </Container>
  )
}

export default Faq

const LeftBarStyled = styled.div`
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 15px;
`

const CustomLink = styled(Link)`
  font-weight: normal;
  color: ${customTheme.colors.black};
  padding: 7px 0;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5px 0;
  transition: color .3s linear;

  span {
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    padding: 0 10px 12px;
  }

  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;

    span {
      border: none;
      margin-bottom: -12px;
    }
  }

  &:hover {
    color: ${customTheme.colors.purple};
  }

  &.active {
    font-weight: bold;
  }

  svg {
    vertical-align: middle;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    color: #9D9D9D;
  }
`

const Text = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: ${customTheme.colors.purple};
  max-width: 500px;
  margin-bottom: 20px;
`

const WppButton = styled.a`
  display: flex;
  align-items: center;
  padding: 0 50px;
  height: 40px;
  background: #25D366;
  border-radius: 100px;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;

  span {
    display: flex;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    margin-right: 10px;
  }
`

const Message = styled.h3`
  color: ${customTheme.colors.purple};
  text-align: center;
  margin: auto;
  font-size: 16px;
`
