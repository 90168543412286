import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormInput } from '../form'
import { Field } from 'formik'
import { Box, Flex } from '../index'
import InputMask from 'react-input-mask'
import { ddiWithMasks } from '../../../../utils/enums/ddi_masks'
import Select from './select'
import TplGroup from './tpl_group'

const InputPhoneWithCode = ({ title, placeholder, isRequired, tabIndex, codeDefault: code, ...el }) => {
  const name = 'phone'
  const nameCode = 'code'

  const [mask, setMask] = useState(ddiWithMasks[code] || '(99) 99999-9999')

  useEffect(() => {
    el.setFieldValue(nameCode, code)
  }, [])

  const selectMask = (e) => {
    setMask(ddiWithMasks[e.target.value])
    el.setFieldValue(nameCode, e.target.value)
    el.setFieldValue(name, '')
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired}>
      <Flex>
        <Box w="20%">
          <Select onChange={selectMask} defaultValue={code} style={{ padding: 0 }}>
            {Object.keys(ddiWithMasks).map((item, idx) => (
              <option key={`ddi-${idx}`} value={item}>+{item}</option>
            ))}
          </Select>
        </Box>
        <Box w="80%">
          <Field name={name}>
            {({ field }) => (
              <InputMask
                {...field}
                type="tel"
                placeholder={placeholder || ''}
                maskChar=""
                tabIndex={tabIndex}
                mask={mask}
                onChange={e => {
                  if (el.setFieldValue) {
                    el.setFieldValue(name, e.target.value)
                  }
                }}
              >
                {inputProps => <FormInput {...inputProps} />}
              </InputMask>
            )}
          </Field>
        </Box>
      </Flex>
    </TplGroup>
  )
}

InputPhoneWithCode.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  codeDefault: PropTypes.string
}

export default InputPhoneWithCode
