import axios from 'axios'

const basicAuth = 'Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuVDJ4cFgydGZVMlZ5ZG1salpWOUZZMjl0YldWeVkyVTZaWGxLYUdKSFkybFBhVXBKVlhwSk1VNXBTamt1VkRCNFNpNW5SV2xmUkU5S2RteFBZV2hmVW5FeGEwRklXbVpsTVU5TGJYSlRlRVJTWlRkSmEwbHZOVzFSUjNWci5XaUI1VXhpel9sSWVjdS1lRmFUTW9uM3BuUWJFcjNmQVJkLWZwRzg5SW5FOmV5SmhiR2NpT2lKSVV6STFOaUo5LlQyeHBYM05mVTJWeWRtbGpaVjlGWTI5dGJXVnlZMlU2WlhsS2FHSkhZMmxQYVVwSlZYcEpNVTVwU2prdVZEQjRTaTVuUldsZlJFOUtkbXhQWVdoZlVuRXhhMEZJV21abE1VOUxiWEpUZUVSU1pUZEphMGx2TlcxUlIzVnIuXzJpNUhBY2tGaDVsc2Z1d0tnRFdCb1V2dEx5clFjOUJQazZrUnVhUlZROA=='

/// SABER SE JÁ FOI ENVIADO O EMAIL PARA OS STEAKHOLDERS
export async function GetSendEmail (email) {
  const apiUrl = `https://api-v2.todasgroup.com.br/api/v1/verify-sended-email?email=${email}`
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify(response.data)
  } catch (error) {
    console.error('Erro ao validar email:', error)
    return false
  }
}

/// ENVIAR  O EMAIL PARA OS STEAKHOLDERS
export async function CreateSendEmail (data) {
  const apiUrl = 'https://api-v2.todasgroup.com.br/api/v1/send-email-mentored'
  try {
    const response = await axios.post(apiUrl, data, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify(response.data)
  } catch (error) {
    console.error('Erro ao criar formulário:', error)
    return false
  }
}

// QUESTIONS by email
export async function GetQuestionsAnswer (email, fase) {
  const apiUrl = `https://api-v2.todasgroup.com.br/api/v1/mentored-qa-by-email?email=${email}&fase=${fase}`
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify(response.data)
  } catch (error) {
    console.error('Erro ao validar email:', error)
    return false
  }
}

// QUESTIONS ANSWER
export async function CreateQuestionsAnswer (data) {
  const apiUrl = 'https://api-v2.todasgroup.com.br/api/v1/mentored-qa'
  try {
    const response = await axios.post(apiUrl, data, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify(response.data)
  } catch (error) {
    console.error('Erro ao criar questions:', error)
    return false
  }
}

// QUESTIONS UPDATE ANSWER
export async function UpdateQuestionsAnswer (data) {
  const apiUrl = 'https://api-v2.todasgroup.com.br/api/v1/update-mentored-qa'
  try {
    const response = await axios.patch(apiUrl, data, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify(response.data)
  } catch (error) {
    console.error('Erro ao editar questions:', error)
    return false
  }
}
