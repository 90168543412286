import {
  addLocalStorage,
  getLocalStorage
  // removeLocalStorage
} from '../../../infra/storage/localStorage'

export const getMentorPermission = () => {
  const permission = getLocalStorage('permission_mentor')
  return JSON.parse(permission) || null
}

export const setUserPermission = async (permission) => {
  await addLocalStorage('permission_mentor', JSON.stringify(permission))
}

export const getZoomLivePermission = () => {
  const permission = getLocalStorage('permission_zoom_live')
  return JSON.parse(permission) || null
}

export const setLivePermission = async (permission) => {
  await addLocalStorage('permission_zoom_live', JSON.stringify(permission))
}

// export const removeUser = () => {
//   removeLocalStorage('user')
// }
