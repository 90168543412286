/* eslint-disable no-console */
import React from 'react'
import styled from 'styled-components'
import { getUser } from '../../../utils/storage/user'
import usersPermissions from '../../../../utils/mentor-users/user-permission'

const StepSix = () => {
  const user = getUser()
  const findPermissionDocuments = usersPermissions.find((item) => {
    return item.email === user?.user?.email && item.documents
  })?.documents

  return (
    <Container>
      <HeaderTitle>Recursos e Material de Apoio</HeaderTitle>
      <NormalText>Compartilhamento de documentos, artigos, vídeos e outros recursos relevantes para o seu desenvolvimento.</NormalText>
      {!findPermissionDocuments
        ? <div><p>Documentos não encontrados</p></div>
        : findPermissionDocuments.map((item) => (
          <List key={item.id}>
            <a href={item.image} target="_blank" rel="noopener noreferrer">{item.name}</a>
          </List>
        ))}
    </Container>
  )
}

export default StepSix

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  margin-top: 30px;
`
const HeaderTitle = styled.h4`
  font-size: 25.6px;
  font-weight: 400;
  color: #121214;
  line-height: 25.6px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`
const NormalText = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #e14b2b;
  line-height: 16px;
  font-family: "Roboto","Open Sans",sans-serif;`

const List = styled.div`
  a {
    display: inline-block;
    width: 70%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    text-decoration: none;
    color: #e14b2b;
    margin-bottom: 10px;
      }
`
