export function outsideEvent (ref, callback) {
  function handleClickOutside (event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }
  document.addEventListener('mousedown', handleClickOutside)
  return () => {
    document.removeEventListener('mousedown', handleClickOutside)
  }
}
