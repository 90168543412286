import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { FormFactoryParams } from '../components/form_factory'

const CurriculumContext = createContext(null)

export const CurriculumProvider = ({ value, children }) => {
  return (
    <CurriculumContext.Provider value={value}>
      {children}
    </CurriculumContext.Provider>
  )
}

CurriculumProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    handleDelete: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    data: FormFactoryParams
  }).isRequired
}

export const useCurriculum = () => {
  const context = useContext(CurriculumContext)

  if (!context) throw new Error('useCurriculum must be used within a provider CurriculumProvider')

  return { ...context }
}
