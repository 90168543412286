import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TplGroup from './tpl_group'
import InputMask from 'react-input-mask'
import { FormInput } from '../form'
import { Field } from 'formik'
import payment from 'payment'

const InputCreditCard = ({ name, title, placeholder, isRequired, tabIndex, ...el }) => {
  const [mask, setMask] = useState('9999 9999 9999 9999')

  const handleChangeMask = (value) => {
    if (!value) return '9999 9999 9999 9999'

    switch (payment.fns.cardType(value)) {
      case 'amex': return '9999 999999 99999'
      case 'dinersclub': return '9999 999999 9999'
      default: return '9999 9999 9999 9999'
    }
  }

  const handleChange = (event) => {
    const { value = '' } = event.target
    if (el.setFieldValue) {
      const currentMask = handleChangeMask(value)
      setMask(currentMask)
      el.setFieldValue(name, value)
    }
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired}>
      <Field name={name}>
        {({ field }) => (
          <InputMask
            {...field}
            placeholder={placeholder || ''}
            tabIndex={tabIndex}
            maskChar=""
            mask={mask}
            onChange={handleChange}
          >
            {inputProps => <FormInput {...inputProps} />}
          </InputMask>
        )}
      </Field>
    </TplGroup>
  )
}

InputCreditCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number
}

export default InputCreditCard
