/* eslint-disable space-before-function-paren */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded'
// import generateToken from './generateToken'
import { getUser } from '../../../utils/storage/user'
import styled from 'styled-components'
import { getZoomLivePermission, setLivePermission } from '../../../utils/storage/mentor'
import { GetLiveZoom, GetDateHours, PostWatchedLive } from '../controller'
import { handleGetUserCompany } from '../../assessment/controller/opt-in'
import modalJson from '../components/modalJson'
import Modal from '../components/modal'

const ZoomMeeting = () => {
  const user = getUser()
  const { formattedDate, formattedHour } = GetDateHours()

  const client = ZoomMtgEmbedded.createClient()
  const [liveDetails, setLiveDetails] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [liveZoom, setLiveZoom] = React.useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const meetingSDKChatElement = document.getElementById('meetingSDKChatElement')

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getDetails = React.useCallback(async () => {
    try {
      const response = await getZoomLivePermission()
      setLiveDetails(JSON.parse(response))
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getDetails()
  }, [getDetails])

  const sdkKey = '4jPChU4iTHuZeO_XGX_YA'
  // const sdkSecret = 'jkyI2p02wr8Lz1XmncbscxoXiRZG0toV'
  // const meetingNumber = '89955734512'
  // const passWord = '107970'
  // const role = 0
  const userName = user?.user?.name
  const userEmail = user?.user?.email
  const registrantToken = ''
  const zakToken = ''

  async function UsersMeetingWatched() {
    try {
      const enterpriseName = await handleGetUserCompany(user?.user?.id)
      const userData = {
        name: user?.user?.name,
        email: user?.user?.email,
        enterprise: enterpriseName[1][0]?.name,
        date: formattedDate,
        hour: formattedHour
      }
      await PostWatchedLive(userData)
    } catch (error) {
      console.log(error)
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function getSignature(e) {
    e.preventDefault()

    // const signature = generateToken(sdkKey, sdkSecret, meetingNumber, role)

    await UsersMeetingWatched()
    startMeeting(liveDetails?.data?.token)
    // chama aqui a função para salvar user que está vendo a live
  }

  function startMeeting(signature) {
    const meetingSDKElement = document.getElementById('meetingSDKElement')

    client.init({
      zoomAppRoot: meetingSDKElement,
      language: 'pt-PT',
      patchJsMedia: true,
      leaveOnPageUnload: true,
      maximumVideosInGalleryView: 3,
      customize: {
        meetingInfo: ['topic', 'host', 'participant', 'dc', 'enctype'],
        video: {
          defaultViewType: 'gallery',
          isResizable: true,
          viewSizes: {
            default: {
              width: windowSize?.width > 600 ? windowSize?.width / 2 : windowSize?.width - 40,
              height: windowSize?.height / 2 - 50
            }
          }
        },
        chat: {
          notificationCls: {
            right: 25,
            top: windowSize?.width < 500 ? windowSize?.height / 6 : windowSize?.height / 4
          },
          popper: {
            disableDraggable: true,
            anchorElement: meetingSDKChatElement,
            placement: windowSize?.width < 500 ? 'bottom' : 'right'
          }
        }
      }
    }).then(() => {
      client.join({
        signature: signature,
        sdkKey: sdkKey,
        meetingNumber: liveDetails?.data?.meetingNumber,
        password: liveDetails?.data?.password,
        userName: userName,
        userEmail: userEmail,
        tk: registrantToken,
        zak: zakToken
      })

        .then((data) => {
          console.log(data, 'RETURN DATA')
          // eslint-disable-next-line no-console
          console.log('joined successfully')
          setLiveZoom(true)
        }).catch(async (error) => {
          console.log(error, 'RETURN error catch 1')
          setLiveZoom(false)
          if (error?.type === 'JOIN_MEETING_FAILED') {
            const response = await GetLiveZoom()
            // eslint-disable-next-line no-console
            console.log(response, 'response')
            //   setLivePermissionTeste(response)
            setLivePermission(response)
            getDetails()
          }

          if (error?.type === 'INVALID_OPERATION') {
            const response = await GetLiveZoom()
            // eslint-disable-next-line no-console
            //  console.log(response, 'response')
            //   setLivePermissionTeste(response)
            setLivePermission(response)
            getDetails()
          }
          // eslint-disable-next-line no-console
          console.log(error)
        })
    }).catch((error) => {
      console.log(error, 'RETURN error catch 2')
      // eslint-disable-next-line no-console
      console.log(error)
    })
  }

  const totalPages = modalJson.length

  const openModal = () => setShowModal(true)
  const closeModal = (e) => {
    setShowModal(false)
    setCurrentPage(0)
    getSignature(e)
  }

  const handleNext = (e) => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1)
    } else {
      closeModal(e)
    }
  }
  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <PageWrapper>
      <Container>

        <HeaderTitle>Mentoria ao vivo</HeaderTitle>
        {/* <SubTitle>Como manter o equilíbrio emocional diante de tantos desafios e incertezas no ambiente de trabalho </SubTitle> */}

        <div id="meetingSDKElement" />
        {!liveZoom && (
          <>
            <StepBtn onClick={openModal}>Entrar na live</StepBtn>
            {showModal && (
              <Modal
                key={currentPage}
                currentPage={currentPage}
                imgModal={modalJson[currentPage].img}
                title={modalJson[currentPage].title}
                description={modalJson[currentPage].description}
                pointDescription={modalJson[currentPage].pointDescription}
                btnBack={modalJson[currentPage].btnBack}
                onNext={handleNext}
                onPrev={handlePrev}
                isFirstPage={currentPage === 0}
                isLastPage={currentPage === totalPages - 1}
                closeModal={closeModal}
              />
            )}
          </>
        )}

      </Container>
    </PageWrapper>
  )
}

export default ZoomMeeting

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  margin-left:15px;
  gap:20px;
  `

const HeaderTitle = styled.h2`
  font-weight: bold;
  font-size:2.2rem;
  line-height: 1;
  font-family: 'Playfair Display', serif;
  color:#3D1252;
  `
// const SubTitle = styled.span`
// font-size:1rem;
// line-height: 1;
// font-family: 'Playfair Display', serif;
// color: #828282;
// `
const StepBtn = styled.button`
display: flex;
border: none;
width: 170px;
min-width: 90px;
height: 50px;
align-items: center;
justify-content: center;
border-radius: 7px;
cursor: pointer;
background-color: #e14b2b;
color:white;
font-size: 1.0rem;
margin-top: 15px;
`
