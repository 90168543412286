import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'
import icoEye from '../../../../assets/icons/ico-eye.png'

export const FormInput = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 1.428;
  color: ${customTheme.colors.black};
  background: transparent;
  border: 0;
  border-bottom: 1px solid ${customTheme.colors.gray['300']};
`

export const FormSelect = styled(FormInput).attrs({ as: 'select' })``

export const TextArea = styled(FormInput).attrs({ as: 'textarea' })`
  height: 80px;
  resize: none;
`

export const IcoView = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url('${icoEye}') no-repeat top center;
  &.slash {
    background-position: bottom center;
  }
`
