import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, ButtonCancel, Flex, Html } from '../../components'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'
import { DateHelper } from '../../../../helpers/date-helper/date-helper'
import ShowMoreText from 'react-show-more-text'
import { Icon } from '../../../../assets/icons'
import { CustomerHelper } from '../../../../helpers/customer-helper/customer-helper'
import { useAlertBox, useModalBox, useCurriculum } from '../../../../contexts'
import FormFactory from '../../../form_factory'

const ExperienceItem = ({ experience, index }) => {
  const item = CustomerHelper.mapper(experience).get()
  const { modalBox } = useModalBox()
  const { alertBox, closeBox } = useAlertBox()
  const { data, title, handleSubmit, handleDelete } = useCurriculum()

  const [dropdown, setDropdown] = useState(false)
  const handleDropdown = () => {
    setDropdown(prev => !prev)
  }

  const handleUpdate = () => {
    const newData = CustomerHelper.setWorkExperienceFormData(data, experience, index)
    modalBox(<FormFactory data={newData} onSubmit={handleSubmit} />, title)
    setDropdown(false)
  }

  const handleDeleteSubmit = () => {
    handleDelete(index)
    closeBox()
  }

  const handleDeleteConfirm = () => {
    alertBox(
      <Box>
        <p>Você deseja excluir: <strong>{item.subtitle.concat(' - ')}{item.title}?</strong></p><br />
        <Flex>
          <ButtonCancel onClick={closeBox} style={{ marginRight: 5 }}>Cancelar</ButtonCancel>
          <Button style={{ marginLeft: 5 }} onClick={handleDeleteSubmit}>Excluir</Button>
        </Flex>
      </Box>
    )
    setDropdown(false)
  }

  return (
    <CustomBox>
      <Flex>
        <h3>{item.title}</h3>
        <Box ml="auto" pos="relative">
          <CustomIcon onClick={handleDropdown}><Icon name="pencil" /></CustomIcon>
          {dropdown && (
            <DropDown>
              <div onClick={handleUpdate}>Editar</div>
              <div onClick={handleDeleteConfirm}>Excluir</div>
            </DropDown>
          )}
        </Box>
      </Flex>

      <p><strong>{item.subtitle}</strong></p>
      <p>
        <strong>Ínicio: </strong> {DateHelper.fullYear(item.startAt)}{'  '}
        {item.endAt
          ? (
            <>
              <strong>Término: </strong> {DateHelper.fullYear(item.endAt)}
            </>
            )
          : <strong>Até o período atual</strong>}
      </p>
      {
        !!item.description && (
          <>
            <p><strong>Descrição</strong>:</p>
            <ShowMoreText lines={4} more="Visualizar mais" less="Visualizar menos">
              <Html html={item.description} />
            </ShowMoreText>
          </>
        )
      }

    </CustomBox>
  )
}

ExperienceItem.propTypes = {
  experience: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default ExperienceItem

const CustomBox = styled(Box)`
  position: relative;
  padding-left: 20px;
  padding-bottom: 30px;
  margin-bottom: 10px;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    width: 11px;
    height: 11px;
    background: ${customTheme.colors.pink['600']};
    top: 5px;
    border-radius: 50%;
    left: 0;
  }

  &:after{
    width: 2px;
    bottom: 0;
    background: ${customTheme.colors.gray['300']};
    top: 26px;
    left: 5px;
  }
  
  h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 21.79px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 24px;
  }

  p {
    font-size: 16px;
    color: ${customTheme.colors.gray['600']}
    strong {
      color: ${customTheme.colors.black}
      font-weight: 700;
    }
  }
`

const CustomIcon = styled.div`
  width: 15px;
  color: ${customTheme.colors.gray['600']};
  margin-left: auto;
  cursor: pointer;
`

const DropDown = styled.div`
  position: absolute;
  right: 25px;
  top: 0;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  > div {
    padding: 4px 10px;
    margin: 2px 0;
    &:hover {
      cursor: pointer;
      color: ${customTheme.colors.pink['600']};
    }
  }
`
