import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { customTheme } from '../../../styles/theme'
import { Flex } from '../../../components/ui/components'
import { useTranslation } from 'react-i18next'

const ConquestItem = ({ content }) => {
  const { t } = useTranslation()

  const TRANSLATE = {
    'Assistir Conteúdos': `${t('conquest_main.obj')}`,
    'Fazer Network': `${t('conquest_main.obj_02')}`
  }

  const atualAwardIndex = content?.awards.indexOf(content?.atualAward)
  const isLastItem = content?.awards?.length - 1 === atualAwardIndex
  let awardList = []

  if (isLastItem) {
    awardList = [
      content?.awards[atualAwardIndex - 1],
      content?.awards[atualAwardIndex]
    ]
  } else {
    awardList = [
      content?.awards[atualAwardIndex],
      content?.awards[atualAwardIndex + 1]
    ]
  }

  return (
    <Card>
      <Title>{TRANSLATE[content.title] ? TRANSLATE[content.title] : content.title}</Title>
      <Flex justifyContent="space-evenly" w="100%" mt={25}>
        {awardList?.map((item, index) => (
          <RewardItem key={index} className={content.currentCustomerProgress < item?.quantity && 'disable'}>
            <img src={item?.badge?.url} />
            <h3>{item?.quantity}</h3>
          </RewardItem>
        ))}
      </Flex>
      <Button to={`/conquistas/${content.slug}`}>{t('conquest_main.obj_03')}</Button>
    </Card>
  )
}

export default ConquestItem

ConquestItem.propTypes = {
  content: PropTypes.any.isRequired
}

const Card = styled.div`
  box-shadow: 1px 1px 5px 0px #cdcdcd;
  padding: 20px;
  border-radius: 5px;
`

const Title = styled.div`
  color: ${customTheme.colors.purple};
  font-weight: bold;
`

const RewardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;

  h3 {
    color: ${customTheme.colors.purple};
    text-align: center;
    font-size: 22px;
  }

  span {
    color: ${customTheme.colors.purple};
    text-align: center;
    font-size: 14px;
    width: 70%;
    display: flex;
    line-height: 1;
  }

  img {
    height: 70px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  &.disable {
    img {
      filter: contrast(.5) grayscale(1);
    }

    h3 {
      color: ${customTheme.colors.gray[200]};
    }

    span {
      color: ${customTheme.colors.gray[200]};
    }
  }
`

const Button = styled(Link)`
  border: solid 1px ${customTheme.colors.purple};
  color: ${customTheme.colors.purple};
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 20px;

  &:hover {
    background: ${customTheme.colors.purple};
    color: #fff;
  }
`
