import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const NotasIcon = (props) => {
  return (
    <NotasStyled {...props}>
      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.99994 12H4.99994C4.44794 12 3.99994 11.552 3.99994 11C3.99994 10.448 4.44794 10 4.99994 10H7.99994C8.55194 10 8.99994 10.448 8.99994 11C8.99994 11.552 8.55194 12 7.99994 12ZM3.99994 15C3.99994 14.448 4.44794 14 4.99994 14H10.9999C11.5529 14 11.9999 14.448 11.9999 15C11.9999 15.552 11.5529 16 10.9999 16H4.99994C4.44794 16 3.99994 15.552 3.99994 15ZM13.4442 18H2.55524C2.24924 18 2.00024 17.776 2.00024 17.5V2.5C2.00024 2.224 2.24924 2 2.55524 2H8.00024V5.15C8.00024 6.722 9.21724 8 10.7142 8H14.0002V17.5C14.0002 17.776 13.7512 18 13.4442 18ZM10.0002 2.978L12.7422 6H10.7142C10.3202 6 10.0002 5.619 10.0002 5.15V2.978ZM15.7402 6.328L10.2962 0.328C10.1062 0.119 9.83824 0 9.55524 0H2.55524C1.14624 0 0.000244141 1.122 0.000244141 2.5V17.5C0.000244141 18.878 1.14624 20 2.55524 20H13.4442C14.8532 20 16.0002 18.878 16.0002 17.5V7C16.0002 6.751 15.9072 6.512 15.7402 6.328Z" fill="#9D9D9D" />
      </svg>

    </NotasStyled>
  )
}

const NotasStyled = styled(IconStyled)``
