import { createGlobalStyle } from 'styled-components'
import { customTheme } from './theme'

export const GlobalStyle = createGlobalStyle`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    outline: none;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }
   
  @keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.25);}
    100% {transform: scale(1);}
  }
  
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.35;
    background-color: #F6F4F3;
    color: ${customTheme.colors.black};
  }
  
  body, input, textarea {
    font-family: ${customTheme.fonts.body};
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${customTheme.fonts.heading};
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
  }
  
  p {
    margin-bottom: 10px;
    margin-top: 0;
  }
  
  .embed-responsive {
    position: relative;
    &::before {
      height: 0;
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  
  .checkbox {
    position: relative;
  }

  .checkbox label {
    background: #E3E4E4;
    color: ${customTheme.colors.purple};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 100%;
    cursor: pointer;
  }

  .checkbox input[type="checkbox"] {
    display: none;
  }

  .checkbox input[type="checkbox"]:checked ~ label {
    background: ${customTheme.colors.purple};
    color: #fff;
  }

   /*
  .checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0;
    position: absolute;
    left: 0;
    margin-left: -20px;
  } */

  /* .checkbox label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 8px;
      width: 20px;
      height: 20px;
      transition: transform 0.28s ease;
      border-radius: 3px;
      border: 2px solid ${customTheme.colors.pink['600']};
  }
  .checkbox label:after {
    content: '';
      display: block;
      width: 10px;
      height: 5px;
      border-bottom: 3px solid ${customTheme.colors.pink['600']};
      border-left: 3px solid ${customTheme.colors.pink['600']};
      -webkit-transform: rotate(-45deg) scale(0);
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      will-change: transform;
      position: absolute;
      top: 14px;
      left: 12.5px;
  } */
  /* .checkbox input[type="checkbox"]:checked ~ label::before {
    color: ${customTheme.colors.pink['600']};
  }

  .checkbox input[type="checkbox"]:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }


  .checkbox input[type="checkbox"]:checked ~ label {
    background: ${customTheme.colors.purple};
    color: #fff;
  }
  .checkbox label span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
  } */
`
