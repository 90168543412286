import React from 'react'
import styled from 'styled-components'
import { IoArrowBack } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'

const Divider = () => {
  const history = useHistory()
  return (
    <div>
      <Container />
      <Arrow onClick={() => history.goBack()}>
        <IoArrowBack size={15} color="#7A7A7A" style={{ marginLeft: '15px' }} />
        <NormalTextGrayMini>Voltar para o academy</NormalTextGrayMini>
      </Arrow>
      <Content>
        <NormalTextGray>
          Aqui estão todas as informações e recursos que você precisa para aproveitar ao máximo o programa de mentoria.<br />
          Lembre-se de que todas as informações do diagnóstico serão confidenciais, e apenas você, sua coach executiva <br /> e mentoras terão acesso a esse documento.
          <br /> No entanto, se você desejar compartilhá-lo com outras pessoas,   fique à vontade para fazer.
        </NormalTextGray>

      </Content>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <MiniDivider />
      </div>
    </div>
  )
}
export default Divider

const Container = styled.div`
  width: 100%;
  height:10px;
  display: flex;
  flex-direction: column;
  background-color: #e14b2b; 
`
const Content = styled.div`
display: flex;
flex-Direction: column;
justify-Content: center; 
align-Items: center;
margin-Top: 20px; 
padding: 0 40px`

const NormalTextGray = styled.p`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 30px;
  font-family: "Roboto","Open Sans",sans-serif;
  `
const MiniDivider = styled.div`
width: 93%;
height:2px;
display: flex;
opacity: 0.3;
flex-direction: column;
background-color: #121214; 
`
const Arrow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap:10px;
  margin-top: 20px;
  color: #7A7A7A;
`

const NormalTextGrayMini = styled.span`
  font-size: 14px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 30px;
  font-family: "Roboto","Open Sans",sans-serif;
  `
