export class DateHelper {
  static fullYear (date) {
    return new Date(date).getFullYear()
  }

  static now () {
    const date = new Date()
    const day = `0${date.getDate()}`.slice(-2)
    const month = `0${date.getMonth() + 1}`.slice(-2)
    const year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  static toInputDate (date) {
    if (!date) return ''
    return date.slice(0, 10)
  }
}

const months = [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez'
]

export const numberMinimum2Digits = number => {
  if (number < 10) {
    return `0${number}`
  }
  return number
}

export const onlyDay = date => {
  const data = new Date(date)

  return numberMinimum2Digits(data.getDate())
}

export const onlyMonth = date => {
  const data = new Date(date)

  return data.getMonth() + 1
}

export const onlyYear = date => {
  const data = new Date(date)

  return data.getFullYear()
}

export const getFullDay = date => {
  if (new Date(date).getDate() === new Date().getDate()) {
    return 'Hoje'
  }
  if (new Date(date).getDate() === new Date().getDate() - 1) {
    return 'Ontem'
  }
  return `${onlyDay(date)} ${months[onlyMonth(date) - 1]} ${onlyYear(date)}`
}

export const getFullTime = (duration, type = null) => {
  let seconds = parseInt((duration / 1000) % 60)
  let minutes = parseInt((duration / (1000 * 60)) % 60)
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds

  if (type === 'min') return minutes + ':' + seconds
  return hours + ':' + minutes + ':' + seconds
}

export const getFullTimeBySeconds = (duration, type = null) => {
  let seconds = parseInt((duration) % 60)
  let minutes = parseInt((duration / (60)) % 60)
  let hours = parseInt((duration / (60 * 60)) % 24)

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds

  if (type === 'min') return minutes + ':' + seconds
  return hours + ':' + minutes + ':' + seconds
}
