import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { customTheme } from '../../../../styles/theme'

const Title = ({ title, bar }) => {
  return (
    <TitleStyled>
      {title}
    </TitleStyled>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  bar: PropTypes.bool
}

export default Title

const TitleStyled = styled.h2`
  color: ${customTheme.colors.purple}!important;
  text-align: center;
  margin: 40px 0;
  font-size: 35px;
`
