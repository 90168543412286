import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { NumberHelper } from '../../../../helpers/number-helper/number-helper'
import { customTheme } from '../../../../styles/theme'
import { Enum } from '../../../../helpers/enums/enums'

const CardMyPlanItem = ({ plan }) => {
  return (
    <Box>
      <Header>{plan.planName}</Header>
      <ul>
        {plan.id > 0 && <li><strong>Código: </strong> {plan.id}</li>}
        {plan.status && <li><strong>Status: </strong> {Enum.status(plan.status)}</li>}
        {plan.frequency && <li><strong>Periodicidade: </strong> {Enum.frequency(plan.frequency)}</li>}
        {plan.billingStatus && <li><strong>Status do pagamento: </strong> {Enum.billingStatus(plan.billingStatus)}</li>}
        {NumberHelper.currency(plan.price) && <li><strong>Preço: </strong> {NumberHelper.currency(plan.price)}</li>}
      </ul>
    </Box>
  )
}

CardMyPlanItem.propTypes = {
  plan: PropTypes.shape({
    planName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    billingStatus: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired
  }).isRequired
}

export default CardMyPlanItem

const Box = styled.div`
  border: 1px solid ${customTheme.colors.purple};
  width: 100%;
  margin-bottom: 20px;

  ul {
    margin: 0;
    padding: 0 5px;
    list-style: none;

    li {
      padding: 15px 0;
      border-bottom: 1px solid ${customTheme.colors.gray['300']};

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`

const Header = styled.div`
  background: ${customTheme.colors.purple};
  color: ${customTheme.colors.white};
  padding: 10px 0;
  text-align: center;
`
