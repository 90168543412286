import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import LogoMobile from '../../assets/images/logo.png'
import LogoDesktop from '../../assets/images/logo_full.png'
import { settings } from '../../../main/configs/settings'
import { Col, Row } from '../ui/layout'
import { Container, Flex, Image } from '../ui/components'
import { customTheme } from '../../styles/theme'
import SelectTranslateI18n from '../i18n/i18n'

const Header = () => {
  const { pathname } = useLocation()

  const currentStep = settings.authSteps.pages.find((page) =>
    page.route.includes(`/${pathname.split('/')[1]}`)
  )

  return (
    <CustomFlex py={20} mb={30}>
      <Container>
        <Row alignItems="center">
          <Col w="30%" mb={0}>
            <Link to="/">
              <ImageMobile
                src={LogoMobile}
                alt={settings.siteName}
                maxW="31px"
              />
              <ImageDesk
                src={LogoDesktop}
                alt={settings.siteName}
                maxW="176px"
              />
            </Link>
          </Col>
          {currentStep && (
            <Col w="50%" ml="auto" textAlign="right" mb={0}>
              <Text>
                <b>Passo:</b> {currentStep.step} de{' '}
                {settings.authSteps.totalSteps}
              </Text>
            </Col>
          )}
        </Row>
      </Container>
      <CustomFlags>
        <SelectTranslateI18n />
      </CustomFlags>
    </CustomFlex>
  )
}

export default Header

const CustomFlex = styled(Flex)`
  @media (min-width: ${customTheme.breakpoints[1]}) {
    box-shadow: 0 4px 22px rgba(0, 0, 0, 0.15);
  }
`

const ImageMobile = styled(Image)`
  @media (min-width: ${customTheme.breakpoints[1]}) {
    display: none;
  }
`

const ImageDesk = styled(Image)`
  display: none;
  @media (min-width: ${customTheme.breakpoints[1]}) {
    display: block;
  }
`
const Text = styled.span`
  color: ${customTheme.colors.purple};
`
const CustomFlags = styled.div`
  margin-right: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
