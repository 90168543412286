import React, { useEffect, useState } from 'react'
import { Flex } from '../../components'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'
import { Link } from 'react-router-dom'
import { getUser } from '../../../../utils/storage/user'
import { makeNetworks } from '../../../../../main/usecases/network/network-factory'
import { makePosts } from '../../../../../main/usecases/todasjuntas/todasjuntas-factory'

const NetworkHeader = () => {
  const { user } = getUser()
  const [values, setValues] = useState({
    unreadNetworks: 0,
    unreadPosts: 0
  })

  const verifyInviter = (content) => {
    return user['@id'] === content?.inviter['@id']
  }

  useEffect(async () => {
    try {
      const data = await makeNetworks().load({ perPage: 1000 })
      const chatList = []
      const reqList = []

      data?.list?.forEach(content => {
        if (verifyInviter(content) && content?.status === 'pending') {
          reqList.push(content)
        } else {
          chatList.push(content)
        }
      })

      const arrayUniqueByKey = [...new Map(reqList.map(item => [item?.content?.id, item])).values()]

      setValues(prev => ({
        ...prev,
        unreadNetworks: [...chatList, ...arrayUniqueByKey].length
      }))

      const posts = await makePosts().load()
      setValues(prev => ({
        ...prev,
        unreadPosts: posts.total
      }))
    } catch (error) {
      console.error('NetworkHeader.useEffect', error)
    }
  }, [])

  return (
    <CustomFlex mb={30} mt={10} justifyContent="flex-start" alignItems="center">
      <Title to="/todas-juntas" active={location.pathname.includes('todas-juntas')}>
        Todas Juntas {!!values.unreadPosts && `(${values.unreadPosts})`}
      </Title>
      <Title to="/networks" active={location.pathname === '/networks'}>
        Network {!!values.unreadNetworks && `(${values.unreadNetworks})`}
      </Title>
    </CustomFlex>
  )
}

export default NetworkHeader

const CustomFlex = styled(Flex)`
  border-bottom: 1px solid ${customTheme.colors.gray[200]};
`

const Title = styled(Link)`
  font-size: ${customTheme.fontSizes.md};
  color: ${customTheme.colors.purple};
  line-height: 1;
  padding: 15px 75px;
  border-right: 2px ${customTheme.colors.gray[200]} solid;
  text-decoration: none;
  font-weight: ${props => props.active && 'bold'};
  border-bottom: ${props => props.active && `3px ${customTheme.colors.purple} solid`};
`
