import React from 'react'
import { makeRemoteMenuFooter } from '../../usecases/menus/footer/menu-footer'
import { AuthLayout } from '../../../presentation/components/layouts'

const makeAuthLayout = ({ children }) => (
  <AuthLayout menuFooter={makeRemoteMenuFooter()}>
    {children}
  </AuthLayout>
)

export default makeAuthLayout
