import styled from 'styled-components'
import { IconStyled } from './default_styled'
import React from 'react'

export const Comma = (props) => {
  return (
    <CommaStyled {...props}>
      <svg focusable="false" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M4.22222 0C5.11111 0 5.77778 0.108527 6.44444 0.434109C7.11111 0.75969 7.55555 1.1938 7.88889 1.73643C8.22222 2.17054 8.55556 2.82171 8.77778 3.5814C8.88889 4.34109 9 5.2093 9 5.96899C9 7.5969 8.55556 9.22481 7.66667 10.6357C6.77778 12.0465 5.44444 13.2403 3.55556 14L3.11111 13.0233C4.11111 12.5891 4.88889 12.0465 5.66667 11.1783C6.44444 10.4186 6.88889 9.55039 7 8.68217C7.22222 7.92248 7.22222 7.27132 7 6.62016C6.33333 7.37985 5.33333 7.70543 4.11111 7.70543C2.88889 7.70543 1.88889 7.37985 1.22222 6.62016C0.333333 6.07752 0 5.10078 0 3.90698C0 2.71318 0.444444 1.73643 1.22222 1.08527C2 0.325581 3 0 4.22222 0Z" />
      </svg>
    </CommaStyled>
  )
}
const CommaStyled = styled(IconStyled)``
