import styled from 'styled-components'
import PropTypes from 'prop-types'
import { customTheme } from '../../../../styles/theme'

export const FormLabel = styled.label.attrs(props => ({
  htmlFor: props.htmlFor
}))`
  font-weight: bold;
  font-size: 13px;
  span {
    color: ${customTheme.colors.pink['600']};
    padding-left: 2px;
  }
`

FormLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired
}
