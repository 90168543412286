import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Icon } from '../../../../assets/icons'
import { customTheme } from '../../../../styles/theme'
import { useTranslation } from 'react-i18next'

const NavBar = ({ menu }) => {
  const { t } = useTranslation()
  const icons = {
    Início: 'home-svg',
    Habilidades: 'diamond-svg',
    Conquistas: 'gift-svg'
  }

  const TRANSLATE = {
    Início: `${t('header_logged.home')}`,
    Habilidades: `${t('header_logged.habilidades')}`,
    Conquistas: `${t('header_logged.conquistas')}`
  }

  return (
    <Items>
      {menu.map(item => (
        <Item key={item['@id']} to={item.url} target={item.target}>
          <div><Icon name={icons[item.title]} size={480} /></div>
          {TRANSLATE[item.title]}
        </Item>
      ))}
    </Items>
  )
}

NavBar.propTypes = {
  menu: PropTypes.array
}

export default NavBar

const Items = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

const Item = styled(Link)`
  color: #2A3C4C;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 25px;
  font-size: 14px;
  
  &:hover div {
    color: ${customTheme.colors.orange};
     svg {
       fill: ${customTheme.colors.orange};
     }
  }
  div {
    color: #828282;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`
