/* eslint-disable no-console */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Globo from './assets/globo.svg'
import Flag from './flag'

const I18n = () => {
  const { i18n } = useTranslation()
  const [menuVisible, setMenuVisible] = useState()

  function handleChangeLanguage (language) {
    i18n.changeLanguage(language)
    setMenuVisible(false)
  }

  const selectedLanguage = i18n.language

  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }

  const languages = [
    { code: 'pt-BR', name: 'Português' },
    { code: 'es-ES', name: 'Español' },
    { code: 'en-US', name: 'Inglês' }
  ]

  const displayLanguage = (lang) => {
    switch (lang) {
      case 'pt-BR':
        return 'Português'
      case 'es-ES':
        return 'Español'
      case 'en-US':
        return 'Inglês'
      default:
        return lang
    }
  }

  return (
    <div style={{ marginLeft: '10px' }}>
      <div
        onClick={toggleMenu}
        style={{
          display: 'inline-block',
          cursor: 'pointer',
          width: '100px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img style={{ margin: '-4px' }} src={Globo} width={20} height={20} />
        <span style={{ fontSize: '14px', margin: '5px' }}>
          {displayLanguage(selectedLanguage)}
        </span>
      </div>
      {menuVisible && (
        <div style={{
          position: 'absolute',
          marginTop: '10px',
          borderRadius: '14px',
          backgroundColor: '#fff',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          width: '110px',
          height: 'auto'
        }}
        >
          {languages.map((language, index) => (
            <Flag
              key={language.code}
              name={language.name}
              isSelected={selectedLanguage === language.code}
              onClick={() => handleChangeLanguage(language.code)}
              style={{
                borderBottom: index !== languages.length - 1 ? '1px solid #ccc' : 'none',
                cursor: 'pointer',
                padding: '8px'
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default I18n
