import { addLocalStorage, getLocalStorage } from '../../../infra/storage/localStorage'

export const getSettings = () => {
  const user = getLocalStorage('settings')
  return JSON.parse(user)
}

export const setSettings = async (settings) => {
  await addLocalStorage('settings', JSON.stringify(settings))
}
