import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { tagsNotes } from '../../helpers/enums/enums'
import { customTheme } from '../../styles/theme'
import { useHistory } from 'react-router-dom'
import { getFullTimeBySeconds } from '../../helpers/date-helper/date-helper'

const NoteItem = ({ index = 1, content, handleClick = false, handleDelete }) => {
  const tag = tagsNotes.find(i => i.value === content.tag)
  const history = useHistory()
  return (
    <Card>
      <h2>{content.title}</h2>
      <span>Nota {String(index).padStart(2, '0')}, em {getFullTimeBySeconds(content.timestamp, 'min')} min</span>
      <ButtonBox>
        <Tag
          style={{
            background: tag?.backgroundColor,
            color: tag?.fontColor
          }}
        >{tag?.label}
        </Tag>
        <Button onClick={() => {
          if (handleClick) {
            handleClick()
          } else {
            history.push({
              pathname: `/notas/${content['@id'].replace('/v1/notes/', '')}`,
              state: { index: index }
            })
          }
        }}
        >Visualizar
        </Button>
        <Button delete onClick={() => handleDelete(content['@id'].replace('/v1/notes/', ''))}>
          Excluir
        </Button>
      </ButtonBox>
    </Card>
  )
}

export default NoteItem

NoteItem.propTypes = {
  index: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  handleClick: PropTypes.any.isRequired,
  handleDelete: PropTypes.any.isRequired
}

const Card = styled.div`
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;

  h2 {
    color: ${customTheme.colors.purple};
    font-size: 18px;
  }

  span {
    font-size: 12px;
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`

const Tag = styled.span`
  border-radius: 26px;
  font-size: 12px;
  height: 32px;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-weight: bold;
  color: #fff;
  margin-right: 15px;
`

const Button = styled.button`
  height: 32px;
  background: ${props => props.delete ? 'rgba(235, 87, 87, 0.12)' : 'rgba(203, 207, 209, 0.44)'};
  border-radius: 6px;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-weight: bold;
  color: ${props => props.delete ? '#EB5757' : customTheme.colors.purple};
  font-size: 12px;
  cursor: pointer;
`
