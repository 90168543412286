import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'
import { NumberHelper } from '../../../../helpers/number-helper/number-helper'
import { Link } from 'react-router-dom'

const PlanInfo = ({ id, plan, price, installments }) => {
  return (
    <div key={id}>
      <Item>
        <div><strong>Plano escolhido: </strong>{plan.name}</div>
        <div>
          <Link to="/planos">Alterar</Link>
        </div>
      </Item>
      <Item>
        <div>
          <strong>Valor: </strong>
          {NumberHelper.currency(price)}
          {!!price && installments > 1 && <strong> até {installments}x</strong>}
        </div>
      </Item>
    </div>
  )
}

PlanInfo.propTypes = {
  id: PropTypes.number.isRequired,
  plan: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired
}

export default PlanInfo

const Item = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${customTheme.colors.gray['300']};
  justify-content: space-between;
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  a {
    color: ${customTheme.colors.black};
  }
  
`
