import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Container } from '../../components/ui/components'
import HeaderListPage from '../../components/ui/layout/header-list-page/header-list-page'
import { Col, Row } from '../../components/ui/layout'
import { makeNotes, makeNotesItem } from '../../../main/usecases/notes/notes-factory'
import NoteItem from './nota-item'
import { useAlertBox } from '../../contexts'
import { customTheme } from '../../styles/theme'

const NotesList = () => {
  const [data, setData] = useState([])
  const { alertBox } = useAlertBox()
  // const [query, setQuery] = useState(null)

  // eslint-disable-next-line no-console
  // console.error(query)

  const handleDelete = async (id) => {
    try {
      await makeNotesItem(id).delete()
      setData(data.filter(item => item['@id'] !== `/v1/notes/${id}`))
      alertBox('Nota excluída')
    } catch (error) {
      console.error('NotesInterna.handleDelete', error)
    }
  }

  useEffect(async () => {
    try {
      const res = await makeNotes().load()
      setData(res['hydra:member'])
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <Container>
      <Box mx="auto" py={[10, 10, 20, 20, 30]}>
        <HeaderListPage title="Notas" setQuery={false} />
        <TitlePage>Notas {!!data.length && `(${data.length})`}</TitlePage>
        {data === null &&
          <NoDataMessage>Nenhum resultado</NoDataMessage>}

        <Row mt={30}>
          {!data.length && <Message>Nenhuma nota</Message>}
          {data.map((item, idx) => (
            <Col key={item['@id']} sm={4}>
              <NoteItem
                index={idx + 1}
                content={item}
                handleDelete={handleDelete}
              />
            </Col>
          ))}
        </Row>
      </Box>
    </Container>
  )
}

export default NotesList

const NoDataMessage = styled.h3`
  text-align: center;
  margin-top: 50px;
`

const TitlePage = styled.h1`
  font-size: 2rem;
  color: #3D1252;
  text-align: center;
`

const Message = styled.h3`
  color: ${customTheme.colors.purple};
  text-align: center;
  margin: auto;
  font-size: 16px;
`
