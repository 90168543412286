import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '../../components'
import { ExperienceItem } from '../'
import { customTheme } from '../../../../styles/theme'
import { useCurriculum, useModalBox } from '../../../../contexts'
import FormFactory from '../../../form_factory'

const ExperienceList = ({ experiences }) => {
  const { modalBox } = useModalBox()
  const { data, title, handleSubmit } = useCurriculum()

  const handleClick = () => {
    modalBox(<FormFactory data={data} onSubmit={handleSubmit} />, title)
  }

  return (
    <Box pos="relative">
      <Plus onClick={handleClick} />
      <Box>
        {experiences.length
          ? experiences.map((experience, index) => (
            <ExperienceItem
              key={experience['@id']}
              index={index}
              experience={experience}
            />
          ))
          : (
            <p>Nenhum registro cadastrado até o momento.</p>
            )}
      </Box>
    </Box>
  )
}

ExperienceList.propTypes = {
  experiences: PropTypes.array.isRequired
}

export default ExperienceList

const Plus = styled.div`
  position: absolute;
  top: -55px;
  right: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${customTheme.colors.gray['600']};
    border-radius: 5px;    
  }
  
  &:after {
    top: 7px;
    width: 16px;
    height: 2px;
    left: 0;
  }
  
  &:before {
    top: 0;
    width: 2px;
    height: 16px;
    left: 7px;
  }
`
