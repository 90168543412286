import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col } from '../index'
import { customTheme } from '../../../../styles/theme'
import { HiVolumeUp } from 'react-icons/hi'
// import { Icon } from '../../../../assets/icons'
import { getImage } from '../../../../utils/image'
import { getFullTime } from '../../../../helpers/date-helper/date-helper'
// import { getFullTime } from '../../../../helpers/date-helper/date-helper'

const CardBookItem = ({ content, exclusiveContentModal, col = 4, favoriteContent }) => {
  return (
    <Col pos="relative" sm={col}>
      <Card to={`/${content?.folder?.slug}/${content?.slug}`}>
        <BookCover>
          <img src={getImage(content.thumb, false)} alt={content.title} />
        </BookCover>
        <CardBody>
          <h3>{content.title}</h3>
          <span>{content?.author?.name}</span>
          <CardBottom>
            <CardBottomItem>
              <HiVolumeUp />
              <span>{getFullTime(content.duration, 'min')} min</span>
            </CardBottomItem>
            {/* <CardBottomItem>
              <Icon name="flag-svg" color={customTheme.colors.purple} />
              <span>Salvar</span>
            </CardBottomItem> */}
          </CardBottom>
        </CardBody>
      </Card>
    </Col>
  )
}

CardBookItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.object.isRequired,
    thumb: PropTypes.object,
    folder: PropTypes.object,
    slug: PropTypes.string,
    hasAccess: PropTypes.string,
    currentContentUserCollection: PropTypes.object,
    duration: PropTypes.string
  }),
  exclusiveContentModal: PropTypes.func,
  col: PropTypes.any,
  favoriteContent: PropTypes.any
}

export default CardBookItem

const Card = styled(Link)`
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.2);
  text-decoration: none;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;

  > img {
    position: absolute;
    right: 0;
    top: 0;
  }

  > i {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    z-index: 1;
  }
`

const BookCover = styled.div`
  position: relative;
  aspect-ratio: 9 / 13;
  overflow: hidden;
  width: 25%;
  margin: auto;
  border-radius: 5px;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
`

const CardBody = styled.div`
  width: 75%;
  padding: 0 5px 0 15px;
  aspect-ratio: 3/1.45;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  i {
    width: 29px;
    color: #AE7576;
  }

  h3 {
    font-size: 18px;
    margin: 0;
    color: ${customTheme.colors.purple};
  }

  span {
    display: flex;
    color: #828282;
    font-size: 14px;
    margin: 15px 0;
  }
`

const CardBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const CardBottomItem = styled.div`
  align-items: center;
  display: flex;

  span {
    margin: 0 0 0 5px;
    color: ${customTheme.colors.purple}
  }
`
