import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Image } from '../../components/image'

const Avatar = ({ src, alt, w, h }) => (
  <CustomAvatar src={src} alt={alt} w={w} h={h} />
)

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  w: PropTypes.number,
  h: PropTypes.number
}

export default Avatar

const CustomAvatar = styled(Image)`
  object-fit: cover;
  width: ${props => props.w ? `${props.w}px` : '20px'};
  height: ${props => props.h ? `${props.h}px` : '20px'};
  border-radius: 50%;
`
