import { RemoteConquests } from '../../../data/usecases/conquests/remote-conquests'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeConquest = () => {
  return new RemoteConquests('/achievements', makeAxiosHttpClient())
}

export const makeChallenges = () => {
  return new RemoteConquests('/challenges', makeAxiosHttpClient())
}
