import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import momentPT from 'moment/locale/pt-br'
// import Avatar from '../../../ui/layout/avatar/avatar'
import { Icon } from '../../../../assets/icons'
import { getUser } from '../../../../utils/storage/user'
import { Box, Button, Flex } from '../../components'
import AlertBox from '../alert_box'
import CommentSubItem from './comment-sub-item'
import { customTheme } from '../../../../styles/theme'

const CommentItem = ({ comment, deleteCommentary, editeCommentary, commentsFunc, contentId }) => {
  moment.updateLocale('pt-br', momentPT)
  const [editedCommentary, setEditedCommentary] = useState(comment.text)
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [editActive, setEditActive] = useState(false)
  const [replyActive, setReplyActive] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [replyContent, setReplyContent] = useState(false)
  const [replyContentTotal, setreplyContentTotal] = useState([])
  const [allSubComments, setAllSubComments] = useState(1)
  const [replyList, setReplyList] = useState([])
  const { user } = getUser()

  const handleEdit = () => {
    setEditActive(true)
    setOptionsOpen(false)
  }

  const replyComment = async () => {
    if (replyContent) {
      try {
        await commentsFunc.post({
          content: `/v1/contents/${contentId}`,
          text: replyContent,
          parent: comment['@id']
        })
        setRefresh(!refresh)
        setReplyActive(false)
      } catch (error) {
        AlertBox(error.message)
      }
    } else {
      alert('Preencha o campo de resposta')
    }
  }

  useEffect(() => {
    try {
      (async () => {
        const contentsChild = await commentsFunc?.loadComment(contentId, comment.id,
          {
            'order[createdAt]': 'DESC',
            perPage: allSubComments
          }
        )
        setReplyList(contentsChild.list)
        setreplyContentTotal(contentsChild.total)
      })()
    } catch (error) {
      AlertBox(error.message)
    }
  }, [refresh, allSubComments, contentId])

  return (
    <CommentItemStyled>
      <div className="head">
        {/* {comment.user.avatar
          ? <Icon name="user" />
          : <Avatar src={comment.user.avatar} alt={comment.user.name} />} */}
        <User>
          <Icon name="user" />
          <div className="texts">
            <h3>{comment.user.name}</h3>
            <span>{moment(comment.createdAt).format('D MMM YYYY')}</span>
          </div>
        </User>

        {user['@id'] === comment.user['@id'] &&
          <div className="options">
            <button onClick={() => setOptionsOpen(!optionsOpen)}>...</button>
            {optionsOpen &&
              <div className="options-list">
                <button onClick={handleEdit}>Editar</button>
                <button onClick={() => deleteCommentary(comment.id)}>Excluir</button>
              </div>}
          </div>}
      </div>
      {editActive
        ? (
          <CommentEdit>
            <textarea
              value={editedCommentary}
              onChange={(e) => setEditedCommentary(e.target.value)}
            />
            <Box w="30%">
              <Button onClick={() => editeCommentary(comment.id, editedCommentary)}>Atualizar</Button>
            </Box>
          </CommentEdit>
          )
        : <p>{comment.text}</p>}

      {!replyActive && <ReplyButton onClick={() => setReplyActive(true)}>Responder</ReplyButton>}

      {replyActive &&
        <Reply>
          <textarea
            onChange={(e) => setReplyContent(e.target.value)}
          />
          <Flex w="40%">
            <Button onClick={() => replyComment()}>Responder</Button>
            <Button onClick={() => setReplyActive(false)}>Fechar</Button>
          </Flex>
        </Reply>}

      {replyList && replyList.map(item => (
        <CommentSubItem
          key={item.id}
          commentsFunc={commentsFunc}
          comment={item}
          deleteCommentary={deleteCommentary}
          editeCommentary={editeCommentary}
          contentId={contentId}
        />
      ))}
      {!!allSubComments && replyContentTotal > 1 && <SeeAllComments onClick={() => setAllSubComments(null)}>Ver mais respostas</SeeAllComments>}
    </CommentItemStyled>
  )
}

export default CommentItem

CommentItem.propTypes = {
  comment: PropTypes.any,
  deleteCommentary: PropTypes.func,
  editeCommentary: PropTypes.func,
  commentsFunc: PropTypes.func,
  contentId: PropTypes.any
}

const CommentItemStyled = styled.div`
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
  min-height: 100px;
  border-radius: 6px;

  .head {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    position: relative;

    .options {
      position: absolute;
      right: 0;
      top: -10px;

      button {
        background: none;
        border: none;
        font-weight: 800;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 20px;
        padding: 0;
        margin: 0;
        color: #676767;
      }

      .options-list {
        display: ${props => props.open ? 'flex' : 'none'};
        display: flex;
        position: absolute;
        background: #f1f3f4;
        right: 0;
        box-shadow: 0 4px 22px rgb(0 0 0 / 15%);
        padding: 5px;
        flex-direction: column;
        align-items: flex-start;
        top: 120%;
        
        button {
          font-size: 14px;
          padding: 15px 5px;
          font-weight: normal;
          display: flex;
        }
      }
    }
  }

  p {
    margin: 0;
    font-size: 14px;
  }
`

const CommentEdit = styled.div`
  textarea {
    width: 100%;
    font-size: 14px;
    height: 100px;
    padding: 10px;
    border: 1px solid #616161;
    border-radius: 5px;
  }
`

const Reply = styled.div`
  padding: 15px 0 ;

  textarea {
    width: 100%;
    font-size: 14px;
    height: 100px;
    padding: 10px;
    border: 1px solid #616161;
    border-radius: 5px;
  }

  button {
    width: fit-content;
    padding: 0 20px;
    margin-right: 10px;
  }
`

const ReplyButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  margin: 15px -5px;
  font-size: 12px;
  color: #313131;
`

const SeeAllComments = styled.button`
  border: none;
  padding: 0;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  margin: 0;
  color: #4F4F4F;
  background: none;
`

const User = styled.div`
  display: flex;

  path {
      fill: ${customTheme.colors.purple};
    }

  .texts {
    padding: 0 10px;
    
    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 1;
      color: ${customTheme.colors.purple};
    }
    
    span {
      color: ${customTheme.colors.gray[200]};
      font-size: 12px;
    }
  }
`
