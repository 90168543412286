import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 7px;

  input {
    padding: 0;
  }
  
  label {
    a {
      color: ${customTheme.colors.pink['600']};
      text-decoration: none;
    }
  }
  
  > div {
    position: relative;
    padding-top: 12px;

    label {
      position: absolute;
      pointer-events: none;
      transition: all .1s linear;
      top: -5px;
      left: 0;
    }
   
    input[disabled] {
      background: rgba(196, 196, 196, .2);
      + label {
        content: '';
        background: rgba(196, 196, 196, .2);
        width: 100%;
        top: -10px !important;
        padding: 4px 3px 0;
      }
    }
    &:not([data-name="phone"]) {
      textarea {
        + label {
          top: -5px;
          left: 0;
          font-weight: bold;
        }
      }

      input {
        transition: al .1s linear;
        -webkit-appearance: none;
        &:focus + label,
        &:not(:placeholder-shown) + label {
          top: -5px;
          left: 0;
          font-weight: bold;
        }
        &::placeholder {
          color: transparent;
        }
        &:required:invalid + label:before {
          content: '*';
        }
      }
      
      select + label {
        top: -5px;
        left: 0;
        font-weight: bold;
      }
      
      label {
        top: calc(50% - 8px);
        left: 0;
        font-weight: bold;
        font-family: "Roboto","Open Sans",sans-serif;
        color: ${customTheme.colors.purple}!important;
      }
    }
  }
`
