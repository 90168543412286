import React from 'react'
import styled from 'styled-components'
import { getUser } from '../../../../utils/storage/user'
import { CreateSendEmail, GetSendEmail } from '../../../../pages/mentor/controller'
import { useAlertBox } from '../../../../contexts'
// import Loading from '../../../loading'

const SendersInit = [{
  id: '1',
  title: 'Líder',
  sub_title: '',
  active: true

},
{
  id: '2',
  title: 'Par 01',
  sub_title: '01',
  active: false
},
{
  id: '3',
  title: 'RH',
  sub_title: '',
  active: false
},
{
  id: '4',
  title: 'Liderado 01',
  sub_title: '01',
  active: false
},
{
  id: '5',
  title: 'Liderado 02',
  sub_title: '02',
  active: false
}
]

const SessionOne = () => {
  const user = getUser()
  const { alertBox } = useAlertBox()
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [senders, setSenders] = React.useState(SendersInit)
  const [formData, setFormData] = React.useState({})
  const currentSender = senders[currentIndex]
  const currentData = formData[currentSender.id] || { nome: '', email: '', telefone: '' }
  const [userInfo, setUserInfo] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const items = Object.values(formData)?.map((item, index) => {
    return {
      name: item.nome,
      email: item.email,
      phone: item.telefone,
      fase: String(index + 1)
    }
  })

  const sendersEmail = {
    email: user?.user?.email,
    item: items,
    hasSended: true,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()

  }

  const getUserDetails = React.useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await GetSendEmail(user?.user?.email)
      setUserInfo(JSON.parse(response))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  // const CreateStakeHolders = React.useCallback(async () => {
  //   await CreateSendEmail(sendersEmail)
  //   await getUserDetails()
  // }, [])

  React.useEffect(() => {
    getUserDetails()
  }, [getUserDetails])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [currentSender.id]: {
        ...currentData,
        [name]: value
      }
    })
  }

  const handleNext = () => {
    if (currentData.nome && currentData.email && currentData.telefone) {
      if (currentIndex < senders.length - 1) {
        const updatedSenders = senders.map((sender, index) => ({
          ...sender,
          active: index === currentIndex + 1
        }))
        setSenders(updatedSenders)
        setCurrentIndex(currentIndex + 1)
      } else {
        // aqui
      }
    } else {
      // alert('Preencha todos os campos')
      alertBox('Preencha todos os campos para continuar')
    }
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const updatedSenders = senders.map((sender, index) => ({
        ...sender,
        active: index === currentIndex - 1
      }))
      setSenders(updatedSenders)
      setCurrentIndex(currentIndex - 1)
    }
  }

  return (
    <Container>
      <SubContent>
        <HeaderTitle>Passo 1. Insights de Stakeholders</HeaderTitle>
        <NormalText>Perspectivas sobre sua Convivência Diária e seu Crescimento Profissional
        </NormalText>
        <NormalTextGray>Queremos entender como as pessoas convivem com você no dia a dia, percebem os seus pontos fortes invisíveis ou até confirmam algo sobre você que ajudará no seu crescimento de carreira.</NormalTextGray>
        <NormalTextGrayBold>Pedimos que indique os seguintes stakeholders ao preencher o formulário abaixo, eles irão responder aos seguintes questionamentos:</NormalTextGrayBold>

        <ul>
          <Details>Quais as características positivas da mentorada que fazem diferença para os negócios?  </Details>
          <Details>O que ela poderia continuar fazendo para manter alta performance?
          </Details>
          <Details>O que você acredita que a mentorada poderia parar de fazer para ser ainda uma líder melhor?</Details>
          <Details>Quais outros insights sobre mentorada que podem ajudar ela a evoluir rapidamente na carreira?
          </Details>
          <Details>O que ela deveria começar a fazer?
          </Details>
        </ul>
      </SubContent>

      {userInfo && userInfo?.data === null && isLoading === false && (
        <>
          <CircleContainer>
            {senders.map((sender, index) => {
              return (
                <div key={sender.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: '100px' }}>
                    <CircleStep active={sender.active}>
                      {sender.id}
                    </CircleStep>
                    <CircleText active={sender.active}>{sender.title}</CircleText>

                  </div>
                  {index < senders.length - 1 ? <Separator /> : null}

                </div>
              )
            })}

          </CircleContainer>

          <div style={{ width: '80%' }}>
            <Label>Nome <Required>*</Required></Label>
            <StyledInput
              name="nome"
              type="text" placeholder="Digite o nome" required value={currentData.nome}
              onChange={handleChange}
            />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ width: '50%' }}>
                <Label>E-mail <Required>*</Required></Label>
                <StyledInput
                  name="email"
                  type="email" placeholder="Digite o email" required value={currentData.email}
                  onChange={handleChange}
                />
              </div>
              <div style={{ width: '50%' }}>
                <Label>Telefone <Required>*</Required></Label>
                <StyledInput
                  maxLength={11}
                  name="telefone"
                  type="text" placeholder="Digite o número" required value={currentData.telefone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginTop: '25px', display: 'flex', gap: '10px' }}>
              {currentIndex > 0 && <ButtonStep onClick={handlePrevious}>Anterior</ButtonStep>}
              {currentIndex < 4 && <ButtonStep onClick={handleNext}>Próximo</ButtonStep>}
              {currentIndex >= 4 && (
                <ButtonStep onClick={async (e) => {
                  e.preventDefault()
                  CreateSendEmail(sendersEmail)
                  getUserDetails()
                }}
                >
                  Enviar
                </ButtonStep>
              )}
            </div>
          </div>
        </>
      )}

      {isLoading && <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><NormalTextGray>Carregando  ...</NormalTextGray></div>}
      {userInfo && userInfo?.data !== null && isLoading === false && (
        <div style={{ marginTop: '15px', textAlign: 'center' }}>
          <NormalTextGrayBold>Pronto! Seus stakeholders receberam um e-mail pedindo para fazerem uma avaliação a seu respeito.</NormalTextGrayBold>
        </div>
      )}
    </Container>
  )
}

export default SessionOne

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  
`

const HeaderTitle = styled.h4`
  font-size:22.4px;
  font-weight: 400;
  color: #121214;
  line-height: 22.4px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`

const NormalText = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #e14b2b;
  line-height: 16px;
  font-family: "Roboto","Open Sans",sans-serif;`

const NormalTextGray = styled.p`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;
  `
const NormalTextGrayBold = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;
  `
const SubContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-top:10px;
`
const Details = styled.li`
font-size: 14.4px;
  font-weight: 400;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;`

const CircleContainer = styled.div`
  width: 100%;
  display: flex;
  height: 90px;
  gap:15px;

  @media(max-width: 600px) {
    overflow: auto;

  }

  `

const CircleStep = styled.div`
height: 35px;
width: 35px;
border-radius: 50%;
border: solid 0.5px;
border-color:${({ active }) => active ? '#e14b2b' : '#7A7A7A'} ;
background-color:${({ active }) => active ? '#e14b2b' : '#f4f4f4'} ;
display:flex;
justify-content: center;
align-items: center;
color:${({ active }) => active ? 'white' : '#7A7A7A'};
font-weight: 500;
font-size: 14px;
`
const Separator = styled.div`
  width: 40px;
  height: 0.5px;
  background-color: #7A7A7A;
  opacity: 0.3;
  margin-left: 15px;
  margin-top:-25px;

  @media(max-width: 600px) {
  width: 30px;
  }
  
  `

const CircleText = styled.span`
color: ${({ active }) => active ? '#e14b2b' : '#7A7A7A'} ;
font-weight: bold;
font-size: 14px;
margin-top: 8px;
margin-left: 2px;
`

const StyledInput = styled.input`
  width: 100%;
  height: 45px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #b6bbc6;
  line-height: 2;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #FFF;
`

const Label = styled.label`
  margin-left: 5px;
  display: flex;
  flex-direction: 'row';
  margin-bottom: -5px;
`
const Required = styled.p`
color: red; 
margin-left: 5px;
`

const ButtonStep = styled.button`
  display: flex;
  width: 150px;
  min-width: 150px;
  height: 40px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #e14b2b; 
  border-color: #e14b2b; 
  color: #FFF;
  font-weight: 700;
  font-size: 16px;`
