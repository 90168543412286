import React from 'react'
import styled from 'styled-components'
import { Box, Container, Flex } from '../ui/components'
import { Col, Row } from '../ui/layout'
import { customTheme } from '../../styles/theme'
import { Link } from 'react-router-dom'
import { Icon } from '../../assets/icons'
import { getLocalStorage } from '../../../infra/storage/localStorage'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// import { SettingsHelper } from '../../helpers/settings-helper/settings-helper'
// import WhatsappImage from '../../assets/images/whatsapp.png'
// import Google from '../../assets/images/goole_play.png'
// import Apple from '../../assets/images/appl_store.png'
// import { SettingsHelper } from '../../helpers/settings-helper/settings-helper'

const Footer = ({ menu = [] }) => {
  const isMobile = getLocalStorage('mobile')
  const { t } = useTranslation()
  // const settingsHelper = new SettingsHelper()
  const navFooter = menu.filter(nav => !nav.icon && nav.url.indexOf('/') === 0)
  const social = menu.filter(nav => nav.icon)

  // const settingsHelper = new SettingsHelper()
  // const googleStore = settingsHelper.loadGooglePlayAppUrl()
  // const appleStore = settingsHelper.loadAppStoreAppUrl()

  return (
    <FooterStyled bg={customTheme.colors.bgFooter} pt={20} pb={15} mt={50} d={isMobile ? 'none' : 'block'}>
      <Container>
        <Row>
          <Col w={['100%', '100%', '30%']} mb={[20, 20, 0]} d={['none', 'none', 'flex']}>
            {t('footer.copyright')}
          </Col>
          <Col w={['100%', '100%', '70%']} mb={0}>
            <Flex justifyContent="flex-end" alignItems="center" d={['block', 'block', 'flex']}>
              <NavFooter mb={[20, 20, 0]} justifyContent={['center', 'center', 'flex-end']}>
                {navFooter.map((item) => (
                  <Link key={item.id} to={item.url}>{item.nickname}</Link>
                ))}
              </NavFooter>
              <Networking justifyContent={['center', 'center', 'flex-end']}>
                {social.map((item) => (
                  <Box mx="3px" key={item.id}>
                    <a href={item.url} target="_blank" rel="noreferrer noopener">
                      <Icon name={item.icon.name} />
                    </a>
                  </Box>
                ))}
              </Networking>
            </Flex>
          </Col>
        </Row>
      </Container>

      {/* <Flex mt={30} justifyContent="space-evenly">
        {googleStore && !settingsHelper.isApple() && (
          <CustomLink href={googleStore}>
            <Image src={Google} alt="Botão que direciona para a loja Google Play" />
          </CustomLink>
        )}
        {appleStore && !settingsHelper.isAndroid() && (
          <CustomLink href={appleStore}>
            <Image src={Apple} alt="Botão que direciona para a loja App Store" />
          </CustomLink>
        )}
      </Flex> */}

      {/* <Box d={['none', 'flex']}>
        <WhatsappButton href={`https://web.whatsapp.com/send?phone=${settingsHelper.loadAppContactPhone()}&text=Olá, tudo bem?\nComo o *Todas Group* pode me ajudar?`}><span>Posso te ajudar?</span></WhatsappButton>
      </Box>

      <Box d={['flex', 'none']}>
        <WhatsappButton href={`https://api.whatsapp.com/send?phone=${settingsHelper.loadAppContactPhone()}&text=Olá, tudo bem?\nComo o *Todas Group* pode me ajudar?`}><span>Posso te ajudar?</span></WhatsappButton>
      </Box> */}
    </FooterStyled>
  )
}

Footer.propTypes = {
  menu: PropTypes.array
}

export default Footer

const FooterStyled = styled(Box)`
  color: ${customTheme.colors.orange};
`

const NavFooter = styled(Flex)`
  a {
    color: ${customTheme.colors.orange};
    text-decoration: none;
    padding: 0 5px;
    @media (min-width: ${customTheme.breakpoints[1]}) {
      padding: 0 15px;
    }
    border-right: 1px solid #fff;
    display: block;
    line-height: 1;
    &:last-of-type {
      border-right: 0;
    }
  }
`

const Networking = styled(Flex)` 
  a {
    display: block;
    color: ${customTheme.colors.orange};
    margin: 0 3px;
  }
`

// const WhatsappButton = styled.a.attrs({
//   rel: 'noreferrer noopener',
//   title: 'WhatsApp Todas Group',
//   target: '_blank'
// })`
//   background: #34AF23 url(${WhatsappImage}) no-repeat top right;
//   width: 48px;
//   height: 48px;
//   position: fixed;
//   bottom: 90px;
//   right: 5px;
//   transition: width .4s ease-in;
//   cursor: pointer;
//   border-radius: 50px;
//   color:#fff;
//   text-decoration: none;
//   z-index: 9;

//   span {
//     opacity: 0;
//     white-space: nowrap;
//   }

//   &:hover {
//     width: 220px;
//     padding-left: 30px;
//     display: flex;
//     align-items: center;
//     font-weight: bold;

//     span {
//       opacity: 1;
//     }
//   }
// `

// const CustomLink = styled.a.attrs({
//   rel: 'noreferrer noopener',
//   target: '_blank'
// })``
