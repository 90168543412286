import React from 'react'
import { Amplify, I18n } from 'aws-amplify'
import Routes from '../routes'
import { AlertBoxProvider } from '../../contexts/alert_box'
import { ModalBoxProvider } from '../../contexts/modal_box'
import { AlertBox, Modal } from '../ui/layout'
import '../../assets/fonts/stars/css/styles.css'
import '../../assets/fonts/didot/didot.css'
import { GlobalStyle } from '../../styles/global'
import { Slick } from '../../styles/slick'
import awsExports from '../../../aws-exports-new'
import { dictionary } from '../../dictionary'
import { ContentHandlerProvider } from '../../contexts/content-handler'
import '../../../utils/i18n/index'

Amplify.configure(awsExports)
I18n.setLanguage('pt_BR')
I18n.putVocabularies(dictionary)

const App = () => {
  return (
    <AlertBoxProvider>
      <ContentHandlerProvider>
        <ModalBoxProvider>
          <GlobalStyle />
          <Slick />
          <Routes />
          <Modal />
          <AlertBox />
        </ModalBoxProvider>
      </ContentHandlerProvider>
    </AlertBoxProvider>
  )
}

export default App
