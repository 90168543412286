import { ddiWithMasks } from '../../utils/enums/ddi_masks'

export const NumberHelper = {
  currency: (number, locale = 'BRL') => {
    return Intl.NumberFormat('pt-br', {
      currency: locale,
      style: 'currency'
    }).format(number)
  },
  sanitize: (string) => {
    if (!string) return ''
    return string.replace(/\W/g, '')
  },
  getPhoneWithCountry: (phoneNumber) => {
    const sanitize = NumberHelper.sanitize(phoneNumber)
    if (!phoneNumber) return sanitize

    const len = sanitize.length
    const numbers = Object.keys(ddiWithMasks).filter(key =>
      `${key}${NumberHelper.sanitize(ddiWithMasks[key])}`.length === len &&
      sanitize.slice(0, key.length) === key
    )

    if (!numbers.length) return { ddi: 55, number: sanitize }

    const ddi = sanitize.slice(0, numbers[0].length)
    const number = sanitize.slice(ddi.length)

    return {
      ddi,
      number
    }
  }
}
