import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import moment from 'moment'
// import momentPT from 'moment/locale/pt-br'
import AlertBox from '../alert_box'
import { SubTitle } from '../index'
import { Icon } from '../../../../assets/icons'
// import Avatar from '../../../ui/layout/avatar/avatar'
import Loading from '../../../loading'
import { Box, Flex } from '../../components'
import CommentItem from './comment-item'
import { SendArrow } from '../../../../assets/icons/send-arrow'
import { customTheme } from '../../../../styles/theme'
import { useTranslation } from 'react-i18next'

const Comments = ({ contentId, comments }) => {
  const [commentsList, setCommentsList] = useState([])
  const [commentsTotal, setCommentsTotal] = useState([])
  const [allComments, setAllComments] = useState(1)
  const [commentContent, setCommentContent] = useState('')
  const [orderBy, setOrderBy] = useState('DESC')
  const [filterOpen, setFilterOpen] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  const sendCommentary = async () => {
    if (commentContent) {
      try {
        const res = await comments.post({
          content: `/v1/contents/${contentId}`,
          text: commentContent
        })
        if (commentsList.length) {
          setCommentsList(prev => ([
            res,
            ...prev
          ]))
        } else {
          setRefresh(!refresh)
        }
        document.getElementById('comment-input').value = ''
        setCommentContent('')
      } catch (error) {
        AlertBox(error.message)
      }
    } else {
      alert(t('watch_content.watch37'))
    }
  }

  const deleteCommentary = async (id) => {
    try {
      await comments.delete(id)
      setRefresh(!refresh)
    } catch (error) {
      console.error(error)
      setRefresh(!refresh)
    }
  }

  const editeCommentary = async (id, value) => {
    try {
      await comments.update(id, {
        text: value
      })
      setRefresh(!refresh)
    } catch (error) {
      console.error(error)
      setRefresh(!refresh)
    }
  }

  const enterSub = e => {
    if (e.key === 'Enter') { sendCommentary() }
  }

  const handleFilter = (type) => {
    setOrderBy(type)
    setFilterOpen(false)
  }

  useEffect(() => {
    setLoading(true)
    try {
      (async () => {
        const contents = await comments.load({
          'order[createdAt]': orderBy,
          perPage: allComments,
          'exists[parent]': false
        })

        setCommentsList(contents.list)
        setCommentsTotal(contents.total)
        setLoading(false)
      })()
    } catch (error) {
      AlertBox(error.message)
    }
  }, [allComments, refresh, orderBy])

  if (loading) {
    return <Loading />
  }

  return (
    <Box my="50px">
      <Flex justifyContent="space-between">
        <SubTitle title={t('watch_content.watch38')} />
        <FilterComments>
          <div className="current" onClick={() => setFilterOpen(!filterOpen)}>
            <span>{orderBy === 'DESC' ? t('watch_content.watch39') : t('watch_content.watch40')}</span>
            <div />
          </div>
          {filterOpen &&
            <div className="drop">
              {orderBy === 'ASC'
                ? <span onClick={() => handleFilter('DESC')}>{t('watch_content.watch39')}</span>
                : <span onClick={() => handleFilter('ASC')}>{t('watch_content.watch40')}</span>}
            </div>}
        </FilterComments>
      </Flex>

      <FormComment>
        <Icon name="user" />

        <input
          id="comment-input"
          onChange={(e) => setCommentContent(e.target.value)}
          placeholder={t('watch_content.watch41')}
          vale={commentContent}
          onKeyUp={e => enterSub(e)}
        />
        <button onClick={() => { sendCommentary() }}>
          <SendArrow color={customTheme.colors.purple} />
        </button>
      </FormComment>

      {!!commentsTotal &&
        <>
          <Box>
            {commentsList && commentsList.map(item => (
              <CommentItem
                key={item.id}
                commentsFunc={comments}
                comment={item}
                deleteCommentary={deleteCommentary}
                editeCommentary={editeCommentary}
                contentId={contentId}
              />
            ))}
          </Box>

          {allComments !== null && allComments < commentsTotal &&
            <SeeAllComments onClick={() => setAllComments(null)}>
              {t('watch_content.watch42')} {commentsTotal - 1} {commentsTotal > 2 ? t('watch_content.watch43') : t('watch_content.watch44')}
            </SeeAllComments>}
        </>}
    </Box>
  )
}

export default Comments

Comments.propTypes = {
  contentId: PropTypes.any,
  comments: PropTypes.any
}

const FilterComments = styled.div`
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding: 0 5px;

  div {
    cursor: pointer;

    &.current {
      display: flex;
      align-items: center;

      div {
        width: 0; 
        height: 0; 
        margin-left: 5px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${customTheme.colors.purple};
        border-radius: 10px;
      }
    }

    &.drop {
      background: #fff;
      display: fel;
      justify-content: center;
      display: flex;
      width: fit-content;
      padding: 2px 5px;
      position: absolute;
      right: 10px;
      top: 20px;
      box-shadow: 0 4px 22px rgb(0 0 0 / 15%);
    }
  }
`

const FormComment = styled.div`
  margin: 20px 0 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;

  svg {
    margin-right: 10px;
    width: 25px;
    height: 25px;

    path {
      fill: ${customTheme.colors.purple};
    }
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    padding: 0 50px 0 15px;
  }

  button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    text-align: center;
    height: 100%;
    padding: 2px 0 0;
    cursor: pointer;
    background: none;
    width: 50px;
    overflow: hidden;
  }
`

const SeeAllComments = styled.button`
  border: none;
  padding: 0;
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  margin: 20px 0;
  color: #4F4F4F;
  background: none;
`
