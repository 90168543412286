import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormErrorMessage, FormLabel } from '../form'
import { ErrorMessage } from 'formik'

const TplGroup = ({ name, title, children, isRequired }) => {
  return (
    <FormControl>
      <div data-name={name}>
        {children}
        {title && <FormLabel htmlFor={name}>{title}{isRequired && <span>*</span>}</FormLabel>}
      </div>
      <ErrorMessage name={name} component={FormErrorMessage} />
    </FormControl>
  )
}

TplGroup.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  isRequired: PropTypes.bool
}

export default TplGroup
