import React from 'react'
import { Link } from 'react-router-dom'
// import striptags from 'striptags'
import moment from 'moment'
import momentPT from 'moment/locale/pt-br'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col } from '../index'
import { Calendar } from '../../../../assets/icons/calendar'
import { LocationIcon } from '../../../../assets/icons/location'
import { Check } from '../../../../assets/icons/check'
import { getImage } from '../../../../utils/image'
import { Box } from '../../components'

const CardJobItem = ({ content }) => {
  moment.updateLocale('pt-br', momentPT)

  return (
    <Col sm="4">
      <Card>
        <Box>
          <Company>
            <img src={getImage(content.company.logo, false)} alt="Logo da empresa" />
            <span>{content.company.name}</span>
          </Company>
          <CardTitle>{content.title}</CardTitle>
          <CardMeta>
            <Calendar />
            <span>Publicado: {moment(content.publishAt, 'YYYY MM DD').calendar().includes('Hoje')
              ? 'Hoje'
              : moment(content.publishAt, 'YYYY MM DD').fromNow()}
            </span>
          </CardMeta>

          {content.state &&
            <CardMeta decoration="underline">
              <LocationIcon />
              <span>{content.state}</span>
            </CardMeta>}
        </Box>
        {/* <p dangerouslySetInnerHTML={{ __html: striptags(content.description) }} /> */}

        <CardButton applied={content.applied} to={`/jobs/${content.slug}`}>
          {content.applied
            ? <> <Check /> Candidatura realizada </>
            : 'Ver mais'}
        </CardButton>
      </Card>
    </Col>
  )
}

export default CardJobItem

CardJobItem.propTypes = {
  content: PropTypes.shape({
    company: PropTypes.object,
    title: PropTypes.string.isRequired,
    publishAt: PropTypes.string,
    state: PropTypes.string,
    // description: PropTypes.string,
    slug: PropTypes.string,
    applied: PropTypes.bool
  })
}

const Card = styled.div`
  background: #fff;
  box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.2);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border-left: solid 5px #ae75767f;
  padding: 20px 15px;
  color: #4f4f4f;
  min-height: 240px;
  justify-content: space-between;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const CardTitle = styled.h2`
  color: #AE7576;
  margin: 10px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const Company = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 7px;
  }

  span {
    color: #AE7576;
    text-transform: uppercase;
    font-size: 14px;
  }
`

const CardMeta = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  i {
    margin-right: 8px;
  }

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-decoration: ${props => props.decoration};
  }
`

const CardButton = styled(Link)`
  color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-decoration: none;
  background: ${({ applied }) => applied ? '#219653' : '#AE7576'};

  i {
    margin-right: 10px;
  }
 
`
