import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { customTheme } from '../../../../styles/theme'

const renderHeading = (attr) => {
  switch (attr) {
    case 'h1':
    case 'h2':
      return css`
      font-weight: 400;
      line-height: 2.55rem;
      text-align: center;
      color: ${customTheme.colors.blue['700']};
    `
    case 'h3': return css`
      font-size: ${customTheme.fontSizes.sm};
      text-align: center;
      font-family: ${customTheme.fonts.body};
      &:after {
        margin-top: 5px !important;
      }
      
      @media (min-width: ${customTheme.breakpoints[2]}) {
        text-align: left;
        &:after {
          margin-left: 0 !important;
        }
      }
    `
    default: return css``
  }
}

export const Heading = styled.h2.attrs(props => ({
  as: props.attr
}))`
  ${(props) => renderHeading(props.attr)};
  position: relative;
  margin-bottom: 40px;
  color: ${customTheme.colors.purple};
  font-size: ${customTheme.fontSizes.xl};
  font-weight: bold;

  /* &:after {
    content: '';
    background: ${customTheme.colors.pink['600']};
    height: 3px;
    width: 43px;
    display: flex;
    margin: 7px auto 0;
  } */
`

Heading.defaultProps = {
  attr: 'h2'
}

Heading.propTypes = {
  attr: PropTypes.oneOf(['h1', 'h2', 'h3']).isRequired
}
