import { getSettings } from '../../utils/storage/settings'

export class SettingsHelper {
  #settings

  constructor () {
    this.#settings = getSettings() || []
  }

  find (name) {
    const value = this.#settings.find(setting => setting.name === name)
    return !value ? null : value.value
  }

  isAndroid () {
    return (/Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  }

  isApple () {
    return (/webOS|iPhone|iPad|iPod/i.test(navigator.userAgent))
  }

  loadRecaptchaSiteKey () {
    return this.find('recaptcha_site_key')
  }

  loadVindiApiUrl () {
    return this.find('vindi_api_url')
  }

  loadVindiPublicKey () {
    return this.find('vindi_public_key')
  }

  loadAppContactPhone () {
    const phone = this.find('app_contact_phone')
    return !phone ? phone : phone.replace(/\W/g, '').toLowerCase()
  }

  loadAppStoreAppUrl () {
    return this.find('app_store_app_url')
  }

  loadGooglePlayAppUrl () {
    return this.find('google_play_app_url')
  }
}

export const getOperationSystem = () => {
  if (navigator.userAgent.indexOf('Win') !== -1) return 'Windows'
  if (navigator.userAgent.indexOf('Mac') !== -1) return 'Macintosh'
  if (navigator.userAgent.indexOf('Linux') !== -1) return 'Linux'
  if (navigator.userAgent.indexOf('Android') !== -1) return 'Android'
  if (navigator.userAgent.indexOf('like Mac') !== -1) return 'iOS'
  return 'Desconhecido'
}

export const getBrowserInfo = () => {
  if (navigator.userAgent.match(/chrome|chromium|crios/i)) return 'Chrome'
  if (navigator.userAgent.match(/firefox|fxios/i)) return 'Firefox'
  if (navigator.userAgent.match(/safari/i)) return 'Safari'
  if (navigator.userAgent.match(/opr\//i)) return 'Opera'
  if (navigator.userAgent.match(/edg/i)) return 'Opera'
  if (navigator.userAgent.match(/edg/i)) return 'Opera'
  return 'Desconhecido'
}
