import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex, Box, Button, ButtonOutline } from '../../../../components/ui/components'
import { customTheme } from '../../../../styles/theme'
import { Icon } from '../../../../assets/icons'
import { useTranslation } from 'react-i18next'

const FilterContent = ({ filter, loadFilter, handleChange, handleClick, handleFilter }) => {
  const [open, setOpen] = useState(false)
  const [filterList, setFilterList] = useState([])
  const { t } = useTranslation()
  const changeSeason = (id) => {
    handleFilter(id)
    setOpen(false)
  }

  const enterSub = e => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  useEffect(async () => {
    try {
      const res = await loadFilter.load()
      setFilterList(res.list)
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <>
      {open &&
        <>
          <ModalDialogOverlay onClick={() => setOpen(false)} />
          <SeasonModal>
            <span>Temporadas</span>
            {!!filterList.length && filterList.map(item => {
              return filter === item.id
                ? <Button key={item.id} onClick={() => changeSeason(null)}>{item.title}</Button>
                : <ButtonOutline key={item.id} onClick={() => changeSeason(item.id)}>{item.title}</ButtonOutline>
            })}
          </SeasonModal>
        </>}

      <Flex w="100%" justifyContent="center">
        <Box pos="relative" w="100%" maxW="500px">
          <Input
            name="search"
            type="text"
            placeholder={t('components.search')}
            onChange={e => handleChange(e.target.value)}
            onKeyUp={e => enterSub(e)}
          />
          <CustomIcon name="search" onClick={() => handleClick()} />
        </Box>
        {!!filterList.length &&
          <FilterButton onClick={() => setOpen(true)}>
            <Icon name="menu" />
          </FilterButton>}
      </Flex>
    </>
  )
}

FilterContent.propTypes = {
  filter: PropTypes.func,
  loadFilter: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default FilterContent

const Input = styled.input`
  border-radius: 20px;
  background: ${customTheme.colors.white};
  height: 40px;
  filter: drop-shadow(0px 4px 8px rgba(189, 189, 189, 0.2));
  border: 0;
  width: 100%;
  padding: 0 40px 0 20px;
`

const CustomIcon = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  width: 20px;
  cursor: pointer;
  color: ${customTheme.colors.pink['600']};  
`

const FilterButton = styled.button`
  width: 40px;
  height: 40px;
  background: #ffffff;
  margin-left: 10px;
  filter: drop-shadow(0px 4px 8px rgba(189, 189, 189, 0.2));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:  pointer;
  border: none;
`

export const ModalDialogOverlay = styled.div`
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 70;
`

export const SeasonModal = styled.div`
  position: fixed;
  width: 80%;
  max-width: 325px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 71;
  box-shadow: 0 0 15px rgb(0 0 0 / 30%);
  border-radius: 5px;
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-bottom: 15px;
  }

  button {
    margin: 5px 0;
    height: 35px;
    font-weight: normal;
  }
`
