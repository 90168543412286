import { RemoteNetwork } from '../../../data/usecases/networks/remote-networks'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeNetworks = () => {
  return new RemoteNetwork('/networks', makeAxiosHttpClient())
}

export const makeNetwork = (id) => {
  return new RemoteNetwork('', makeAxiosHttpClient())
}

export const makeNetworkNew = (id) => {
  return new RemoteNetwork(`/networks/${id}`, makeAxiosHttpClient())
}

export const makeNetworkInvites = () => {
  return new RemoteNetwork('/network_invites', makeAxiosHttpClient())
}

export const makeNetworkFilters = (id) => {
  return new RemoteNetwork(`/contents/${id}/network_filters`, makeAxiosHttpClient())
}
