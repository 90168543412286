import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { customTheme } from '../../../styles/theme'
import { useTranslation } from 'react-i18next'

export const SeasonProgressItem = ({ content, episodes, current = false }) => {
  const eps = episodes.filter(i => i.season.slug === content.slug)
  const { t } = useTranslation()

  const TRANSLATE = {
    //  Metodologia: `${t('watch_content.watch14')}`
    Mente: `${t('description_skills.title1')}`,
    Modelagem: `${t('description_skills.title2')}`,
    Metodologia: `${t('description_skills.title3')}`
  }

  if (!eps.length) return null

  return (
    <Card active={current} className="progress-item">
      <h4>{TRANSLATE[content.title] ? TRANSLATE[content.title] : content.title}</h4>
      {eps.map(item => (
        <Line key={item.id} fill={item?.currentContentUserCollection?.registration} />
      ))}
    </Card>
  )
}

SeasonProgressItem.propTypes = {
  content: PropTypes.any.isRequired,
  episodes: PropTypes.any.isRequired,
  current: PropTypes.any.isRequired
}

const Card = styled.div`
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 40px;
  border-color: ${props => props.active ? customTheme.colors.purple : '#D8D8D8'};

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #203B4C;
  }
`

const Line = styled.div`
  height: 4px;
  background: ${props => props.fill ? '#25D366' : '#D8D8D8'};
  border-radius: 6px;
  width: 100%;
  margin: 8px 0;
`
