import React, { useState, useEffect } from 'react'
import { Box, Container, Flex } from '../../../components/ui/components'
import { Col, Row } from '../../../components/ui/layout'
import Loading from '../../../components/loading'
// import ExclusiveContentModal from '../../../components/ui/layout/exclusive-content-modal/exclusive-content-modal'
import HeaderListPage from '../../../components/ui/layout/header-list-page/header-list-page'
import CardDefaultItem from '../../../components/ui/layout/card-default-item/card-default-item'
import { customTheme } from '../../../styles/theme'
// import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { makeLoadContentsByEndpoint } from '../../../../main/usecases/contents/load-contents-factory'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ContentsWatched = () => {
  const [data, setData] = useState(null)
  const { t } = useTranslation()
  // const [query, setQuery] = useState('')
  const [endpoint, setEndpoint] = useState('/watched_contents')
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  // const [totalData, setTotalData] = useState(0)
  // const [season, setSeason] = useState(null)
  // const [query, setQuery] = useState(null)
  // const [modalExclusive, setModalExclusive] = useState(false)

  // const [jobFilter, setJobFilter] = useState({})
  // const [jobFilterParams, setJobFilterParams] = useState({})

  // const [refresh, setRefresh] = useState(true)

  // const search = () => {
  //   setData([])
  //   setTotalData(0)
  //   setCurrentPage(1)
  //   setRefresh(!refresh)
  //   setJobFilterParams({})
  // }

  // const ApplyFilter = (value) => {
  //   setData([])
  //   setCurrentPage(1)
  //   setSeason(value)
  // }

  // const exclusiveContentModal = () => {
  //   setModalExclusive(!modalExclusive)
  // }

  // window.onscroll = function () {
  //   const pgQuantity = Math.ceil(totalData / paramsType[slug].perPage)
  //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //     if (
  //       !loading &&
  //       currentPage < pgQuantity &&
  //       data.length &&
  //       totalData > data.length
  //     ) {
  //       setCurrentPage(currentValue => currentValue + 1)
  //     }
  //   }
  // }

  useEffect(async () => {
    setLoading(true)
    try {
      setData(null)
      const contents = await makeLoadContentsByEndpoint(endpoint).load()
      setData(contents.list)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  // }, [refresh, currentPage, modalExclusive, season, jobFilterParams, location.pathname])
  }, [endpoint])

  useEffect(() => {
    if (location.hash) setEndpoint(location.hash.replace('#', '/'))
  }, [location])

  return (
    <Container>
      <Box mx="auto" py={[10, 10, 20, 20, 30]}>
        <HeaderListPage title={t('profile.watch')} setQuery={false} />

        <Row>
          <Col xs={12} sm={3} d={['none', 'block', 'block']}>
            <LeftBarStyled>
              <CustomLink
                onClick={() => setEndpoint('/watched_contents')}
                className={endpoint === '/watched_contents' ? 'active' : ''}
              >
                <span>{t('watch_content.watch01')}</span>
              </CustomLink>
              <CustomLink
                onClick={() => setEndpoint('/in_progress_contents')}
                className={endpoint === '/in_progress_contents' ? 'active' : ''}
              >
                <span>{t('watch_content.watch02')}</span>
              </CustomLink>
              <CustomLink
                onClick={() => setEndpoint('/favorite_contents')}
                className={endpoint === '/favorite_contents' ? 'active' : ''}
              >
                <span>{t('watch_content.watch03')}</span>
              </CustomLink>
            </LeftBarStyled>
          </Col>
          <Col xs={12} sm={9}>
            <Row>
              {data !== null && !data.length && <Message>{t('watch_content.watch04')}</Message>}
              {data && data.map((item) => (
                <CardDefaultItem
                  key={item.id}
                  content={item}
                  col={6}
                />
              ))}
            </Row>
          </Col>
        </Row>

        {loading &&
          <Flex w="100%" justifyContent="center"><Loading /></Flex>}
      </Box>
    </Container>
  )
}

export default ContentsWatched

// const Description = styled.div`
//   text-align: center;
//   width: 550px;
//   max-width: 100%;
//   margin: auto;
//   font-size: 16px;
//   margin-bottom: 30px;
// `

const LeftBarStyled = styled.div`
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 15px;
`

const CustomLink = styled.button`
  font-weight: normal;
  color: ${customTheme.colors.black};
  padding: 7px 0;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5px 0;
  transition: color .3s linear;
  border: none;
  display: flex;
  width: 100%;
  background: no-repeat;
  text-align: left;
  cursor: pointer;
  
  span {
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    padding: 0 10px 12px;
  }
  
  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }
  
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;  

    span {
      border: none;
      margin-bottom: -12px;
    }
  }
  
  &:hover {
    color: ${customTheme.colors.purple};
  }

  &.active {
    font-weight: bold;
  }
  
  svg {
    vertical-align: middle;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    color: #9D9D9D;
  }
`

const Message = styled.h3`
  color: ${customTheme.colors.purple};
  text-align: center;
  margin: auto;
  font-size: 16px;
`
