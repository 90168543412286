import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Card } from './card.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as Logout } from './logout.svg'
import { ReactComponent as Notepad } from './notepad.svg'
import { ReactComponent as Pencil } from './pencil.svg'
import { ReactComponent as Unlock } from './unlock.svg'
import { ReactComponent as Camera } from './camera.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Menu } from './menu.svg'
import { ReactComponent as Ballons } from './ballons.svg'
import { ReactComponent as Book } from './book.svg'
import { ReactComponent as Community } from './community.svg'
import { ReactComponent as Diamond } from './diamond.svg'
import { ReactComponent as Jobs } from './jobs.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as Gift } from './gift.svg'
import { ReactComponent as Eye } from './eye.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as Flag } from './flag.svg'
import { ReactComponent as ChatBallon } from './chat-ballon.svg'
import { ReactComponent as Potencia } from './potencia.svg'
import { ReactComponent as Legenda } from './legenda.svg'

const IconSvg = ({ name, ...rest }) => {
  switch (true) {
    case /home/.test(name): return <Home {...rest} />
    case /chat-ballon/.test(name): return <ChatBallon {...rest} />
    case /card/.test(name): return <Card {...rest} />
    case /gift/.test(name): return <Gift {...rest} />
    case /star/.test(name): return <Star {...rest} />
    case /eye/.test(name): return <Eye {...rest} />
    case /flag/.test(name): return <Flag {...rest} />
    case /heart/.test(name): return <Heart />
    case /logout/.test(name): return <Logout {...rest} />
    case /notepad/.test(name): return <Notepad {...rest} />
    case /pencil/.test(name): return <Pencil {...rest} />
    case /unlock/.test(name): return <Unlock {...rest} />
    case /camera/.test(name): return <Camera {...rest} />
    case /search/.test(name): return <Search {...rest} />
    case /user/.test(name): return <User {...rest} />
    case /menu/.test(name): return <Menu {...rest} />
    case /ballons/.test(name): return <Ballons {...rest} />
    case /book/.test(name): return <Book {...rest} />
    case /community/.test(name): return <Community {...rest} />
    case /diamond/.test(name): return <Diamond {...rest} />
    case /jobs/.test(name): return <Jobs {...rest} />
    case /potencia/.test(name): return <Potencia {...rest} />
    case /legenda/.test(name): return <Legenda {...rest} />
    default: return null
  }
}

IconSvg.propTypes = {
  name: PropTypes.string.isRequired
}

export default IconSvg
