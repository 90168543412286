export const getUrlValues = (query) => {
  let queryString = {}
  if (query) {
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      const key = decodeURIComponent(pair[0])
      const value = decodeURIComponent(pair[1])
      if (typeof queryString[key] === 'undefined') {
        queryString[key] = decodeURIComponent(value)
      } else if (typeof queryString[key] === 'string') {
        const arr = [queryString[key], decodeURIComponent(value)]
        queryString[key] = arr
      } else {
        queryString[key].push(decodeURIComponent(value))
      }
    }
  } else {
    queryString = false
  }
  return queryString
}
