/* eslint-disable no-console */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { getUser } from '../../utils/storage/user'
import { useHistory } from 'react-router-dom'
import { userAcessLive } from './controller'

import ZoomMeeting from './zoom'

const ZoomLivePage = () => {
  const userId = getUser().user?.id
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      const response = await userAcessLive(userId)
      if (!response?.data?.permission) {
        history.replace('/')
      }
    }
    fetchData()
  }, [])

  return (
    <>

      <Container>

        <ZoomMeeting />

      </Container>

    </>
  )
}

export default ZoomLivePage

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap:13px;
  margin-bottom: 50px;
  margin-top: 30px;
  @media(max-width: 820px) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  }

`

// const SplitLeft = styled.div`
//   height: 100%;
//   top:10;
//   padding: 40px 0px 0px 0px;
//   left: 20;
//   margin-Left: 40px;
//   margin-Top: 30px;
//   @media(max-width: 820px) {
//   margin-Left: 0px;
//   }

// `
// const SplitRight = styled.div`
//   height: 100%;
//   width:100%;
//   z-index: 1;
//   top: 10;
//   margin-left: 35px;
//   overflow-y: auto;
//   overflow: auto;
//   @media(max-width: 820px) {
//    //padding-Left: 10px;
//   margin-bottom: 30px;
//   }

// `
