import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteCommentsContent {
  #url
  #httpClient

  constructor (url, httpClient) {
    this.#url = url
    this.#httpClient = httpClient
  }

  async load (params) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'get',
      params: {
        perPage: 1,
        'order[createdAt]': 'desc',
        ...params
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return {
        list: httpResponse.body['hydra:member'],
        total: httpResponse.body['hydra:totalItems']
      }
      default: throw new UnexpectedError()
    }
  }

  async loadComment (contentId, commentId, params) {
    const httpResponse = await this.#httpClient.request({
      url: `/contents/${contentId}/comments/${commentId}/childrens`,
      method: 'get',
      'order[createdAt]': 'ASC',
      params: {
        ...params
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return {
        list: httpResponse.body['hydra:member'],
        total: httpResponse.body['hydra:totalItems']
      }
      default: throw new UnexpectedError()
    }
  }

  async post (body) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'post',
      data: body
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  async update (id, body) {
    const httpResponse = await this.#httpClient.request({
      url: `/comments/${id}`,
      method: 'put',
      data: body
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  async delete (id) {
    const httpResponse = await this.#httpClient.request({
      url: `${this.#url.split('?')[0]}/${id}`,
      method: 'delete'
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.noContent: return true
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
