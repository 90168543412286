import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Image } from '../../../components/ui/components'
import ModalJson from './modalJson'

const Modal = ({ title, imgModal, btnBack, description, pointDescription = [], onNext, onPrev, isLastPage, isFirstPage, currentPage, closeModal }) => {
  return (
    <Box>
      <Container>
        <Content>

          <Close onClick={closeModal}>X</Close>
          <Image src={imgModal} alt="image modal descrição" />
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
          {pointDescription.length > 0 && (
            <List>
              {pointDescription.map((point, index) => (
                <Line key={index}>{point}</Line>
              ))}
            </List>
          )}
        </Content>

        <ContainerBottom>

          <Progress>
            {Array(ModalJson?.length)
              .fill(0)
              ?.map((_, index) => (
                <Indicator
                  key={index}
                  isActive={index === currentPage}
                />
              ))}
          </Progress>
          <ContainerBtn>
            {btnBack && !isFirstPage && <BackBtn onClick={onPrev}>Voltar</BackBtn>}
            <NextBtn onClick={isLastPage ? onNext : onNext}>
              {isLastPage ? 'Concluir' : 'Próximo'}
            </NextBtn>
          </ContainerBtn>
        </ContainerBottom>
      </Container>
    </Box>
  )
}

export default Modal

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  imgModal: PropTypes.string.isRequired,
  btnBack: PropTypes.bool,
  description: PropTypes.string,
  pointDescription: PropTypes.arrayOf(PropTypes.string),
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
  currentPage: PropTypes.any,
  closeModal: PropTypes.any
}
const Box = styled.div`
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 70;
`

const Content = styled.div`
padding:40px;
@media (max-width: 500px) {
    padding:30px;
    margin-top: 10px;
  }
`

const Container = styled.div`
  position: fixed;
 //padding: 40px;
 // width: 90%;
  max-width: 550px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 620px;
  display: flex;
  z-index: 71;
  box-shadow: 0 0 15px rgb(0 0 0 / 30%);
  flex-direction: column;
  border-radius: 20px;
  min-height:  650px;
 // position: relative;

  @media (max-width: 500px) {
    width: 90%;
  }
`
const Close = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  border: 0px;
  cursor: pointer;
  background-color: transparent;
  right: 6px;
  top: 6px;
`
const Title = styled.h2`
  color: black;
`
const Description = styled.p`
  margin-top: 18px;
  color: gray;
  font-size: 15px;
`

const ContainerBottom = styled.div`
height: 100px;
width: 100%;

position: absolute ;
bottom: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


`
const ContainerBtn = styled.div`
  display: flex;
 // position: absolute;
//  width: ${(props) => props.currentPage === 0 ? '100%' : '85%'};
 // bottom: 25px;
  gap: 30px;
  align-items: center;
  justify-content: center;
`
const NextBtn = styled.button`
  display: flex;
  border: none;
  width: 200px;
  min-width: 90px;
  font-weight: bold;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: #e14b2b;
  color:white;
  font-size: 1.0rem;
  margin-top: 15px;
  @media (max-width: 500px) {
    width: 140px;
  }
  
`
const BackBtn = styled.button`
  display: flex;
  border: none;
  width: 200px;
  min-width: 90px;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: grey;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  color: grey;
  font-size: 1.0rem;
  margin-top: 15px;

  @media (max-width: 500px) {
    width: 140px;
  }
`
const List = styled.ul`
`
const Line = styled.li`
  margin-top: 14px;
  color: gray;
  font-size: 15px;
`

const Progress = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
 // position: absolute;
 // width: 85%;
  bottom: 85px;
  gap: 5px;
`
const Indicator = styled.div`
  width: 6px;
  height: 6px;
  margin: 0 2px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  background-color: ${(props) => (props.isActive ? 'black' : 'gray')};`
