import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import { Box, Container } from '../../../components/ui/components'
import {
  Col,
  Row
} from '../../../components/ui/layout'
import { customTheme } from '../../../styles/theme'
import { getImage } from '../../../utils/image'
import PlayButton from '../../../assets/images/play_circle_outline.png'
import { Link } from 'react-router-dom'
import { BiPlayCircle } from 'react-icons/bi'
import { fancyTimeFormat } from '../../../utils/time_format'
import { useTranslation } from 'react-i18next'
import TransLate from './translate-hook'

export const ContentItem = ({ content, skillId }) => {
  const progress = (content?.currentContentUserCollection?.view?.progress * 100000) / content?.duration
  const translate = TransLate()

  return (
    <Card to={`/habilidades/${skillId}/${content.slug}`}>
      <ImageBox>
        <CardImage src={getImage(content.thumb)} alt="" />
        <PlayButtonImg src={PlayButton} alt={content.title || content.thumb.url} />
      </ImageBox>
      <span>{fancyTimeFormat(content.duration / 1000)}</span>
      {/* <h3>{translate[content.title] ? translate[content.title] : content.title}</h3> */}
      <h3>{translate[content.title] ? translate[content.title] : content.title}</h3>
      <ProgressBarContainer>
        <BiPlayCircle />
        <ProgressBar percent={progress} />
        <span>{fancyTimeFormat(content?.currentContentUserCollection?.view?.progress / 1000)}</span>
      </ProgressBarContainer>
    </Card>
  )
}

ContentItem.propTypes = {
  content: PropTypes.any.isRequired,
  skillId: PropTypes.any.isRequired
}

const SkillSeason = ({ content, episodes, skillId }) => {
  const [open, setOpen] = useState(true)
  const eps = episodes.filter(i => i.season.slug === content.slug)
  const { t } = useTranslation()

  const TRANSLATE_HEADER = {
    Mente: `${t('description_skills.title1')}`,
    Modelagem: `${t('description_skills.title2')}`,
    Metodologia: `${t('description_skills.title3')}`,
    'Entendendo como seu cérebro reage a essa habilidade': `${t('description_skills.sub1')}`,
    'Aprendendo com a experiência de uma mulher referência nessa habilidade': `${t('description_skills.sub2')}`,
    'Passo a passo para desenvolver essa habilidade em você': `${t('description_skills.sub3')}`
  }

  if (!eps.length) return null

  return (!!content &&
    <SkillSeasonStyled>
      <SkillSeasonHead open={open} onClick={() => setOpen(!open)}>
        <h3>{TRANSLATE_HEADER[content.title] ? TRANSLATE_HEADER[content.title] : content.title}</h3>
        <span>{TRANSLATE_HEADER[content.description] ? TRANSLATE_HEADER[content.description] : content.description}</span>
        <div />
      </SkillSeasonHead>
      <SkillSeasonBody open={open}>
        <Row>
          {eps.map(item => (
            <Col key={item.id} sm={4}>
              <ContentItem
                content={item}
                skillId={skillId}
              />
            </Col>
          ))}
        </Row>
      </SkillSeasonBody>
    </SkillSeasonStyled>
  )
}

SkillSeason.propTypes = {
  content: PropTypes.any.isRequired,
  skillId: PropTypes.any.isRequired,
  episodes: PropTypes.any.isRequired
}

export default SkillSeason

const SkillSeasonStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 0 24px;
  margin-bottom: 16px;
`

const SkillSeasonHead = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  border-bottom: ${props => props.open ? '1px solid #D8D8D8;' : 'none'};
  position: relative;
  cursor: pointer;
  font-size: 25px;
  line-height: 29px;
  color: ${customTheme.colors.purple};

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #828282;
    display: flex;
    margin: 10px 0 0 10px;
  }

  >div {
    border-left: 3px ${customTheme.colors.purple} solid;
    border-top: 3px ${customTheme.colors.purple} solid;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: ${props => props.open ? 'translateY(-50%) rotate(45deg)' : 'translateY(-80%) rotate(225deg)'};
    width: 10px;
    height: 10px;
  }
`

const SkillSeasonBody = styled.div`
  height: ${props => props.open ? 'auto' : '0'};
  padding: ${props => props.open ? '15px' : '0'};
  overflow: hidden;
  color: ${customTheme.colors.purple};
`

const Card = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  height: auto;

  h3 {
    color: ${customTheme.colors.purple};
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 26px;
  }

  span {
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: ${customTheme.colors.purple};
    letter-spacing: 0.02em;
    margin-bottom: 5px;
  }
`

const ImageBox = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 312 / 191;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  text-decoration: none;
  margin-bottom: 10px;

  &:before {
    content: '';
    background: ;
    background: linear-gradient(transparent, ${customTheme.colors.purple});
    position: absolute;
    bottom: 0;
    left: 0;
    height: 90%;
    width: 100%;
    z-index: 1;
  }
`

const CardImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`

const PlayButtonImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  span {
    margin-bottom: 1px;
  }

  svg {
    font-size: 22px;
    color: ${customTheme.colors.purple};
  }
`

const ProgressBar = styled.div`
  position: relative;
  width: 80%;
  height: 8px;
  background: ${customTheme.colors.gray[300]};
  overflow: hidden;
  border-radius: 8px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${props => props.percent + '%'};
    background: #1ed462;
    z-index: 1;
  }
`
