import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../../../../assets/icons'
import { Typography } from '../../components/typography'
import { customTheme } from '../../../../styles/theme'
import styled from 'styled-components'

const Nickname = ({ name }) => {
  return (
    <CustomNick attr="div">
      {name}
      <Icon
        name="comma"
        style={{
          color: customTheme.colors.pink['600'],
          paddingTop: 5,
          marginLeft: 5,
          verticalAlign: 'bottom'
        }}
      />
    </CustomNick>
  )
}

Nickname.propTypes = {
  name: PropTypes.string.isRequired
}

export default Nickname

const CustomNick = styled(Typography)`
  white-space: nowrap;
`
