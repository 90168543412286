/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useContentHandler } from '../../../../contexts/content-handler'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import axios from 'axios'
import { HiVolumeOff, HiVolumeUp } from 'react-icons/hi'
import { BsPlayFill, BsPauseFill, BsCheck } from 'react-icons/bs'
import { BiFullscreen, BiDotsVerticalRounded, BiLeftArrowAlt } from 'react-icons/bi'
import { ImPlay2, ImPause } from 'react-icons/im'
import { customTheme } from '../../../../styles/theme'
import { makeNotes } from '../../../../../main/usecases/notes/notes-factory'
import { tagsNotes } from '../../../../helpers/enums/enums'
import { getFullTime } from '../../../../helpers/date-helper/date-helper'
import { outsideEvent } from '../../../../utils/outside_event'

import IconSvg from '../../../../assets/icons/svg/icon-svg'

import { useTranslation } from 'react-i18next'

const VideoFile = ({ contentId, reference, duration, type, onListenContent, onFinishContent, noNotes, idVimeo, previewReference, lastEpisodes }) => {
  const { setCurrentTimeVideo, setTabShow, setDisplayNote } = useContentHandler()
  const video = useRef(null)
  const [time, setTime] = useState(0)
  const [notes, setNotes] = useState([])
  const [videoDuration, setVideoDuration] = useState(0)
  const [volume, setVolume] = useState(100)
  const [isActiveOpt, setIsActiveOpt] = useState(false)
  const [isActiveSpeed, setIsActiveSpeed] = useState(false)
  const [activeSpeed, setActiveSpeed] = useState(1)

  const [activeLanguage, setActiveLanguage] = useState(false)
  const [checkVimeoLanguage, setCheckVimeoLanguage] = useState(false)
  // const [check, setCheck] = useState('')
  const [languagePt, setLanguagePt] = useState('')
  const [languageEn, setLanguageEn] = useState('')
  const [languageEs, setLanguageEs] = useState('')
  const [finishedEpisodes, setFinishedEpisodes] = useState(false)

  const { t } = useTranslation()

  const videoOptions = useRef(null)

  outsideEvent(videoOptions, () => {
    setIsActiveOpt(false)
    setIsActiveSpeed(false)
  })

  const openFullscreen = () => {
    if (video?.current?.requestFullscreen) {
      video?.current?.requestFullscreen()
    } else if (video?.current?.webkitRequestFullscreen) { /* Safari */
      video?.current?.webkitRequestFullscreen()
    } else if (video?.current?.msRequestFullscreen) { /* IE11 */
      video?.current?.msRequestFullscreen()
    }
  }

  const handlePlayPause = () => {
    if (video?.current?.paused) {
      video?.current?.play()
    } else {
      video?.current?.pause()
    }
  }

  const handleVolume = (value) => {
    video.current.volume = value / 10
  }

  const handleVideoSpeed = (value) => {
    setActiveSpeed(value)
    console.warn(activeSpeed)
    video.current.playbackRate = value
  }

  const handleNote = (id) => {
    setDisplayNote(id)
    setTabShow('notes-list')
  }

  // eslint-disable-next-line space-before-function-paren
  async function iniciarPIP() {
    const video = document.querySelector('#video-player')
    await video.requestPictureInPicture()
  }

  useEffect(async () => {
    try {
      const res = await makeNotes().load()
      setNotes(res['hydra:member'].filter(item => item?.content?.id === contentId))
    } catch (error) {
      console.error(error)
    }
  }, [])

  if (video === null) return null

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      let srtValue
      if (idVimeo) {
        srtValue = idVimeo
      } else {
        srtValue = previewReference
      }
      const vimeoIdVideo = srtValue.replace('?h=', ':')
      let data = {}

      try {
        if (vimeoIdVideo) {
          const accessToken = process.env.REACT_APP_TOKEN_VIMEO
          const response = await axios.get(`https://api.vimeo.com/videos/${vimeoIdVideo}/texttracks`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          data = response.data
        }
        if (data) {
          const resEn = data?.data?.find(item => item.active && (item.language === 'en' || item.language === 'en-US'))
          const resPt = data?.data?.find(item => item.active && item.language === 'pt-BR')
          const resEs = data?.data?.find(item => item.active && (item.language === 'es' || item.language === 'es-419'))

          if (isMounted) {
            if (resEn) {
              setLanguageEn(resEn.link)
              setCheckVimeoLanguage(true)
            }
            if (resPt) {
              setLanguagePt(resPt.link)
              setCheckVimeoLanguage(true)
            }
            if (resEs) {
              setLanguageEs(resEs.link)
              setCheckVimeoLanguage(true)
            }
          }
        }
      } catch (error) {
        console.log('Error fetching video tracks:', error)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  const manageLegend = (trackId) => {
    const videoElement = document.getElementById('video-player')
    // setCheck(trackId)

    if (videoElement) {
      for (let i = 0; i < videoElement.textTracks.length; i++) {
        const track = videoElement.textTracks[i]
        track.mode = track.id === trackId ? 'showing' : 'disabled'
      }
    }
  }

  return (
    <VideoBox>
      <video
        ref={video}
        id="video-player"
        // autoPlay
        controlsList="nodownload"
        onTimeUpdate={e => {
          onListenContent('audio', e)
          setTime(e.target.currentTime * 1000)

          if (e.target.duration - e.target.currentTime <= 10) {
            setFinishedEpisodes(true)
          }
        }}
        onEnded={() => {
          setTimeout(() => { onFinishContent() }, 6000)
        }}
        crossOrigin="anonymous"
        onCanPlay={(e) => setVideoDuration(e.target.duration * 1000)}
      >
        <source src={reference} type={type} />
        seu navegador não suporta HTML5
        {checkVimeoLanguage &&
          <><track id="legenda1" kind="subtitles" src={languagePt} srcLang="pt-BR" label="Português" />
            <track id="legenda2" kind="subtitles" src={languageEn} srcLang="en" label="English" />
            <track id="legenda3" kind="subtitles" src={languageEs} srcLang="es" label="Espanhol" />
          </>}
      </video>
      <div className="overlay" onClick={handlePlayPause}>
        {video?.current?.paused ? <ImPlay2 /> : <ImPause />}
      </div>
      <TimeLine>
        {!noNotes &&
          <TagsLine>
            {notes.map(item => {
              const tag = tagsNotes.find(i => i.value === item.tag)
              return (
                <TagTime
                  key={item.value}
                  onClick={() => handleNote(item['@id'])}
                  style={{
                    background: tag.fontColor,
                    left: `${(item.timestamp * 100000) / videoDuration > 100 ? 100 : (item.timestamp * 100000) / videoDuration}%`
                  }}
                />
              )
            })}
          </TagsLine>}

        <Controls>
          <LeftControls>
            <PlayPause onClick={handlePlayPause}>
              {video?.current?.paused ? <BsPlayFill /> : <BsPauseFill />}
            </PlayPause>
            <VideoTime>{getFullTime(time, 'min')} / {getFullTime(videoDuration, 'min')}</VideoTime>
          </LeftControls>

          <RightControls>
            {finishedEpisodes && lastEpisodes === false
              ? (
                <ButtonNextVideo onClick={onFinishContent}>
                  Próximo Vídeo
                </ButtonNextVideo>)
              : null}

            <Volume>
              <InputRange
                maxValue={10}
                minValue={0}
                step={1}
                value={volume}
                onChange={e => {
                  handleVolume(e)
                  setVolume(e)
                }}
              />
              {volume > 0 ? <HiVolumeUp /> : <HiVolumeOff />}
            </Volume>
            {checkVimeoLanguage &&
              <Legendas>
                <button
                  onClick={() => {
                    setIsActiveOpt(false)
                    setIsActiveSpeed(false)
                    setActiveLanguage(!activeLanguage)
                  }}
                >
                  <IconSvg name="legenda" />
                </button>
              </Legendas>}
            <FullScreen onClick={openFullscreen}>
              <BiFullscreen />
            </FullScreen>
            <VideoOpts ref={videoOptions}>
              {isActiveOpt &&
                <VideoOptsList>
                  <button
                    onClick={() => {
                      setIsActiveOpt(false)
                      setIsActiveSpeed(prev => !prev)
                    }}
                  >
                    {t('video_config.config01')}
                  </button>
                  <button
                    onClick={() => {
                      setIsActiveOpt(false)
                      iniciarPIP()
                    }}
                  >
                    Tela avulsa
                  </button>
                </VideoOptsList>}

              {isActiveSpeed &&
                <VideoSpeedOpts>
                  <button
                    onClick={() => {
                      setIsActiveOpt(true)
                      setIsActiveSpeed(false)
                    }}
                  >
                    <BiLeftArrowAlt />   {t('video_config.config02')}
                  </button>
                  <button onClick={() => handleVideoSpeed(0.25)}>{activeSpeed === 0.25 && <BsCheck />} 0.25</button>
                  <button onClick={() => handleVideoSpeed(0.5)}>{activeSpeed === 0.5 && <BsCheck />} 0.5</button>
                  <button onClick={() => handleVideoSpeed(0.75)}>{activeSpeed === 0.75 && <BsCheck />} 0.75</button>
                  <button onClick={() => handleVideoSpeed(1)}>{activeSpeed === 1 && <BsCheck />} {t('video_config.config03')}</button>
                  <button onClick={() => handleVideoSpeed(1.25)}>{activeSpeed === 1.25 && <BsCheck />} 1.25</button>
                  <button onClick={() => handleVideoSpeed(1.5)}>{activeSpeed === 1.5 && <BsCheck />} 1.50</button>
                  <button onClick={() => handleVideoSpeed(1.75)}>{activeSpeed === 1.75 && <BsCheck />} 1.75</button>
                  <button onClick={() => handleVideoSpeed(2)}>{activeSpeed === 2 && <BsCheck />} 2</button>
                </VideoSpeedOpts>}

              {activeLanguage &&
                <VideoSpeedOpts>
                  <button
                    onClick={() => {
                      setActiveLanguage(false)
                    }}
                  >
                    <BiLeftArrowAlt /> Voltar
                  </button>
                  {languagePt && <button onClick={() => { manageLegend('legenda1'); setActiveLanguage(false) }}>Português</button>}
                  {languageEn && <button onClick={() => { manageLegend('legenda2'); setActiveLanguage(false) }}>Inglês</button>}
                  {languageEs && <button onClick={() => { manageLegend('legenda3'); setActiveLanguage(false) }}>Espanhol</button>}
                  <button onClick={() => {
                    const videoElement = document.getElementById('video-player')
                    if (videoElement) {
                      for (let i = 0; i < videoElement.textTracks.length; i++) {
                        videoElement.textTracks[i].mode = 'disabled'
                      }
                    }
                    ; setActiveLanguage(false)
                  }}
                  >
                    Desligar
                  </button>
                </VideoSpeedOpts>}

              <BiDotsVerticalRounded
                onClick={() => {
                  setIsActiveSpeed(false)
                  setIsActiveOpt(prev => !prev)
                }}
              />
            </VideoOpts>
          </RightControls>
        </Controls>

        <InputRange
          maxValue={videoDuration || 100}
          onChangeComplete={e => {
            setCurrentTimeVideo(e)
            setTabShow('notes')
            if (!noNotes) {
              video?.current?.pause()
            } else {
              video?.current?.play()
            }
          }}
          minValue={0}
          step={1}
          value={time}
          onChange={(e) => {
            setTime(e)
            video.current.currentTime = e / 1000
          }}
        />
      </TimeLine>
    </VideoBox>
  )
}

VideoFile.propTypes = {
  contentId: PropTypes.any.isRequired,
  reference: PropTypes.object.isRequired,
  type: PropTypes.any.isRequired,
  duration: PropTypes.any.isRequired,
  onListenContent: PropTypes.func.isRequired,
  onFinishContent: PropTypes.func.isRequired,
  noNotes: PropTypes.any.isRequired,
  idVimeo: PropTypes.any.isRequired,
  previewReference: PropTypes.any,
  lastEpisodes: PropTypes.bool
}

export default VideoFile

const VideoBox = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &:hover {
    .overlay {
      display: flex;
    }
  }

  .overlay {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(359.76deg, ${customTheme.colors.purple} 6.02%, rgba(32, 59, 76, 0) 99.79%);
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: #fff;
      font-size: 50px;
    }
  }
`

const TimeLine = styled.div`
  position: absolute;
  width: 100%;
  bottom: 9px;
  padding: 0px 10px;
  z-index: 3;

  .input-range__label {
    display: none;
  }

  .input-range__slider {
    background: #fff;
    border: 1px solid #fff;
  }

  .input-range__track {
    background: rgba(255, 255, 255, 0.4);
  }

  .input-range__track--active {
    background: #fff;
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
`

const LeftControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const RightControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
const ButtonNextVideo = styled.button`
    border-radius: 10px;
    background-color: #D9D9D9;
    width: 132px;
    height: 28px;
    border: 0px;
    cursor: pointer;
    font-weight: 600;
    position: absolute;
    bottom: 60px;
`

const TagsLine = styled.div`
  background: rebeccapurple;
  position: relative;
  width: calc(100%);
  z-index: 5;
  height: 0;
  margin-bottom: -28px;
`

const TagTime = styled.div`
  position: absolute;
  cursor: pointer;
  height: 18px;
  width: 8px;
  background: red;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`

const Volume = styled.div`
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 110px;
  margin-right: -9px;

  .input-range {
    margin-right: 12px;
    width: 0;
    overflow: hidden;
    transition: ease all 200ms;
  }

  &:hover {
    .input-range {
      width: 79px;
      overflow: unset;
    }
  }
`

const PlayPause = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0 0 0 -5px;
  cursor: pointer;
  color: #fff;
  display: flex;
  font-size: 24px;
`

const FullScreen = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0 -3px 0 5px;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
  display: flex;

`

const VideoTime = styled.span`
  display: flex;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
`

const VideoOpts = styled.div`
  border: none;
  background: none;
  padding: 0;
  margin: 0 -3px 0 5px;
  color: #fff;
  font-size: 22px;
  display: flex;
  position: relative;

  svg {
    cursor: pointer;
  }
`

const VideoOptsList = styled.div`
  position: absolute;
  right: 9px;
  bottom: 115%;
  background: #fff;

  button {
    width: 160px;
    background: none;
    cursor: pointer;
    font-size: 12px;
    border: none;
    padding: 9px 0;
  }
`

const VideoSpeedOpts = styled.div`
  position: absolute;
  right: 9px;
  bottom: 115%;
  background: #fff;
  display: flex;
  flex-direction: column;
  /* height: 160px; */
  height: auto;
  max-height: 160px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f0ee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${customTheme.colors.gray[500]};
  }

  button {
    width: 150px;
    background: none;
    cursor: pointer;
    font-size: 12px;
    border: none;
    border-bottom: 1px ${customTheme.colors.gray[200]} solid;
    padding: 7px 30px;
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      font-size: 20px;
      position: absolute;
      left: 6px;
    }
  }
`

const Legendas = styled.div`
  width: 22px;
  height: 22px;

  button {
    border: none;
    background: transparent;
  }

  svg {
    width: 22px;
    height: 22px;
  }
`
