import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const ContentHandlerContext = createContext(null)

export const ContentHandlerProvider = ({ children }) => {
  const [currentTimeVideo, setCurrentTimeVideo] = useState(null)
  const [tabShow, setTabShow] = useState('description')
  const [time, setTime] = useState(0)
  const [displayNote, setDisplayNote] = useState(0)

  return (
    <ContentHandlerContext.Provider value={{
      currentTimeVideo,
      setCurrentTimeVideo,
      tabShow,
      setTabShow,
      time,
      setTime,
      displayNote,
      setDisplayNote

    }}
    >
      {children}
    </ContentHandlerContext.Provider>
  )
}

ContentHandlerProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useContentHandler = () => {
  const context = useContext(ContentHandlerContext)

  if (!context) throw new Error('useContentHandler must be used within a provider ContentHandlerProvider')

  return { ...context }
}
