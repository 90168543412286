import React from 'react'
import styled from 'styled-components'
import SideStep from '../../components/steps-mentor/side-step'
import StepOne from '../../components/steps-mentor/stepOne/step-one'
import StepSix from '../../components/steps-mentor/stepSix/step-six'
import { getUser } from '../../utils/storage/user'
import StepTwo from '../../components/steps-mentor/stepTwo/step-two'
import StepThree from '../../components/steps-mentor/stepThree/step-three'
import Banner from '../../components/steps-mentor/Banner/banner'
import Divider from '../../components/steps-mentor/Banner/divider'
import Loading from '../../components/loading'
import usersPermissions from '../../../utils/mentor-users/user-permission'
import { useHistory } from 'react-router-dom'
import StepFour from '../../components/steps-mentor/stepFour/step-four'
import StepFive from '../../components/steps-mentor/stepFive/step-five'
const MentorPage = () => {
  const [loading, setLoading] = React.useState(true)
  const user = getUser()
  const history = useHistory()

  const findPermission = usersPermissions.find((item) => {
    return item.email === user?.user?.email
  })

  React.useEffect(() => {
    if (!findPermission) {
      alert('Você não tem permissão para acessar essa página')
      history.push('/')
    }
  }, [user])

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const [session, setSession] = React.useState({ id: '1' })

  return (
    <>
      <Banner />
      <Divider />
      {loading && <div style={{ marginTop: '15px' }}><Loading /></div>}

      {loading === false && (

        <Container>
          <SplitLeft>
            <SideStep setSession={setSession} />
          </SplitLeft>
          <SplitRight>
            <div style={{
              marginRight: '20px',
              marginTop: '65px'
            }}
            >
              {session?.id === '1' && (
                <StepOne />

              )}
              {session?.id === '2' && (
                <StepTwo />

              )}
              {session?.id === '3' && (
                <StepThree />

              )}

              {session?.id === '4' && (
                <StepFour />

              )}

              {session?.id === '5' && (
                <StepFive />

              )}

              {session?.id === '6' && (
                <StepSix />

              )}
            </div>
          </SplitRight>
        </Container>
      )}
    </>
  )
}

export default MentorPage

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap:13px;
  margin-bottom: 50px;
  margin-top: 30px;
  @media(max-width: 820px) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  }

`

const SplitLeft = styled.div`
  height: 100%;
  top:10;
  padding: 40px 0px 0px 0px;
  left: 20;
  margin-Left: 40px;
  margin-Top: 30px;
  @media(max-width: 820px) {
  margin-Left: 0px;
  }

`
const SplitRight = styled.div`
  height: 100%;
  width:100%;
  z-index: 1;
  top: 10;
  margin-left: 35px;
  overflow-y: auto;
  overflow: auto;
  @media(max-width: 820px) {
   //padding-Left: 10px;
  margin-bottom: 30px;
  }

`
