import { RemoteLoadContent } from '../../../data/usecases/contents/remote-load-content'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeLoadSkill = (id) => {
  return new RemoteLoadContent(`/skills/${id}`, makeAxiosHttpClient())
}

export const makeSeasons = (id) => {
  return new RemoteLoadContent('/seasons', makeAxiosHttpClient())
}

export const makeContentsbyParams = (folderId, seasonSlug) => {
  return new RemoteLoadContent(`/contents?folder.slug=${folderId}&season.slug=${seasonSlug}&order[publishAt]`, makeAxiosHttpClient())
}
