import React from 'react'
import PropTypes from 'prop-types'
import { Button as ButtonUI } from '../index'

const Button = ({ title, submittingText, isSubmitting, withDisable, ...el }) => {
  const disabledAttr = withDisable
    ? {
        disabled: !(el.isValid && el.dirty)
      }
    : {}

  return (
    <ButtonUI type="submit" {...disabledAttr}>{!isSubmitting ? title : submittingText}</ButtonUI>
  )
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  submittingText: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  withDisable: PropTypes.bool
}

export default Button
