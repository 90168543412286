import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Icon } from '../../../assets/icons'
import { Box } from '../../ui/components'
import styled, { css } from 'styled-components'
import { customTheme } from '../../../styles/theme'
import { outsideEvent } from '../../../utils/outside_event'
import { useTranslation } from 'react-i18next'

const SearchWidget = () => {
  const [searchInput, setSearchInput] = useState(false)
  const [searchContent, setSearchContent] = useState('')
  const input = useRef(null)
  const history = useHistory([])
  const { t } = useTranslation()

  const verifyContent = () => {
    if (searchContent === '') {
      setSearchInput(false)
    } else {
      history.push({
        pathname: '/busca',
        state: searchContent
      })
    }
  }

  outsideEvent(input, () => {
    if (searchContent === '') {
      setSearchInput(false)
    }
  })

  const searchAction = (e) => {
    e.preventDefault()
    verifyContent()
  }

  const enterSub = e => {
    if (e.key === 'Enter') {
      verifyContent()
    }
  }

  return (
    <BoxIcon mt={5}>
      <Icon name="search" onClick={() => setSearchInput(!searchInput)} />
      <FormSearch open={searchInput}>
        <Input
          id="input"
          ref={input}
          type="text"
          placeholder={t('components.search')}
          onChange={e => setSearchContent(e.target.value)}
          onKeyUp={e => enterSub(e)}
        />
        <CustomIcon name="search" onClick={e => searchAction(e)} />
      </FormSearch>
    </BoxIcon>
  )
}

export default SearchWidget

const BoxIcon = styled(Box)`
  position: relative;

  svg {
    width: 16px;
    color: ${customTheme.colors.gray['500']};
    cursor: pointer;
  }
`

const FormSearch = styled(Box)`
  position: absolute;
  right: -15px;
  top: -24px;
  background: #fff;
  overflow: hidden;
  transition: ease all 300ms;
  height: 65px;
  display: flex;
  align-items: center;
  
  ${props => props.open
  ? css`
    width: 500px;
    padding: 30px 10px 30px;
  `
  : css`
    width: 0;
    padding: 28px 0;
  `};

`

const Input = styled.input`
  border-radius: 5px;
  background: ${customTheme.colors.white};
  height: 35px;
  filter: drop-shadow(0px 4px 8px rgba(189, 189, 189, 0.2));
  border: 0;
  width: 100%;
  padding: 0 40px 0 20px;
  box-shadow: 0px 4px 8px rgb(189 189 189 / 20%);
`

const CustomIcon = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  width: 20px;
  cursor: pointer;
  color: ${customTheme.colors.pink['600']};  
`
