import React from 'react'
import styled from 'styled-components'
import { IconStyled } from '../default_styled'

export const IconZIP = (props) => {
  return (
    <ZipStyled {...props}>
      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7502 0H1.49314C0.670298 0 0 0.660896 0 1.47181V22.5279C0 23.34 0.670298 24 1.49314 24H16.5065C17.3303 24 18 23.34 18 22.5279V4.55095L13.7502 0ZM14.1026 2.20247L15.9653 4.19751H14.1026V2.20247ZM16.7459 22.5279C16.7459 22.6575 16.6381 22.764 16.5059 22.764H1.49314C1.36127 22.764 1.25415 22.6575 1.25415 22.5279V1.47181C1.25415 1.34186 1.36127 1.23659 1.49314 1.23659H9.13985V1.76661H7.51736V2.65933H9.13985V4.41143H7.51736V5.30384H9.13985V6.9837H7.51736V7.87611H9.13985V7.9431V7.99434V9.31582H7.51736V10.2085H9.13985V11.9606H7.51736V12.8534H9.13985V14.5329H7.51736V15.4253H8.38499C8.05328 15.4417 7.78923 15.7093 7.78923 16.0396V16.3918H7.79425V19.0616C7.79425 19.755 8.36682 20.3192 9.07032 20.3192C9.77382 20.3192 10.3461 19.755 10.3461 19.0616V16.3918H10.3467V16.0396C10.3467 15.6988 10.0661 15.4222 9.72026 15.4222H9.7024V14.0523H11.1921V13.1596H9.7024V11.48H11.1921V10.5873H9.7024V8.83489H11.1921V7.94248H9.7024V6.50277H11.1921V5.61036H9.7024V3.93049H11.1921V3.03808H9.7024V1.28568H11.1921V1.23629H12.8475V4.8155C12.8475 5.15628 13.1291 5.43256 13.4752 5.43256H16.7455V22.5279H16.7459ZM9.88501 17.487V18.9786C9.88501 19.4265 9.51635 19.7889 9.0628 19.7889C8.60894 19.7889 8.24059 19.4265 8.24059 18.9786V17.487H9.88501Z" fill="#4F4F4F"/>
      </svg>
    </ZipStyled>
  )
}

const ZipStyled = styled(IconStyled)`
  margin: 0 5px;
`
