import { RemoteNotes } from '../../../data/usecases/notes/remote-notes'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeNotes = () => {
  return new RemoteNotes('/notes', makeAxiosHttpClient())
}

export const makeNotesItem = (id) => {
  return new RemoteNotes(`/notes/${id}`, makeAxiosHttpClient())
}
