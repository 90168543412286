import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const SendArrow = ({...props}) => {
  return (
    <SendArrowStyled {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={props.color} fill-rule="evenodd" clip-rule="evenodd" d="M2.74023 2.25195L4.10524 11.807L4.87699 11.9999L4.10524 12.1929L2.74023 21.7479L22.2362 11.9999L2.74023 2.25195ZM5.89476 10.193L5.25977 5.74805L17.7638 12.0001L5.25977 18.2521L5.89476 13.8071L13.123 12.0001L5.89476 10.193Z"/>
      </svg>
    </SendArrowStyled>
  )
}

const SendArrowStyled = styled(IconStyled)``

