import { addLocalStorage, getLocalStorage } from '../../../infra/storage/localStorage'
import { settings } from '../../../main/configs/settings'

export class MobileHelper {
  static async setMobile (search) {
    const params = new URLSearchParams(search)

    if (params.has('mobile')) await addLocalStorage('mobile', 1)
  }

  static isMobile () {
    return !!getLocalStorage('mobile')
  }

  static backToApp (screen) {
    const url = this.makeUrlToRedirect(screen)
    window.location.replace(url)
  }

  static makeUrlToRedirect (screen) {
    if (screen) {
      return `${settings.REDIRECT_MOBILE}?screen=${screen}`
    } else {
      return `${settings.REDIRECT_MOBILE}?screens=splash`
    }
  };
}
