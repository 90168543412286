import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { customTheme } from '../../styles/theme'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import usersPermissions from '../../../utils/mentor-users/user-permission'

const MenuMobile02 = ({ closeModal, menu, user }) => {
  const { t } = useTranslation()

  const match = useRouteMatch()
  const node = useRef(null)
  const [dropdown, setDropdown] = useState(false)

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) return
    setDropdown(false)
  }

  useEffect(() => {
    if (dropdown) document.addEventListener('mousedown', handleClickOutside)
    else document.removeEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [dropdown])

  useEffect(() => {
    setDropdown(false)
  }, [match])

  const findPermission = usersPermissions.find((item) => {
    return item.email === user.email
  })

  return (
    <Box>
      <Container>
        <Content>

          <Close onClick={closeModal}>X</Close>
          {menu.map(item => (
            <BoxDropItem key={item['@id']} to={item.url} target={item.target}>
              <span>{item.title}</span>
            </BoxDropItem>))}
          <BoxDropItem to="/resultado-perfil-inicial">
            <span>{t('header_logged.potencias')}</span>
          </BoxDropItem>

          <BoxDropItem to="/editar-perfil">
            <span>{t('profile.me')}</span>
          </BoxDropItem>
          <BoxDropItem to="/notas">
            <span>{t('profile.notes')}</span>
          </BoxDropItem>
          <BoxDropItem to="/conteudos">
            <span>{t('profile.watch')}</span>
          </BoxDropItem>

          {findPermission && (
            <BoxDropItem to="/mentoria">
              <span>Mentorada</span>
            </BoxDropItem>
          )}

          <BoxDropItem to="/faq">
            <span>{t('profile.todas')}</span>
          </BoxDropItem>
          <BoxDropItem to="/sair">
            <span>{t('profile.out')}</span>
          </BoxDropItem>

        </Content>

      </Container>
    </Box>
  )
}

export default MenuMobile02

MenuMobile02.propTypes = {
  closeModal: PropTypes.any,
  menu: PropTypes.array,
  user: PropTypes.object.isRequired
}

const Box = styled.div`
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 70;
`

const Content = styled.div`
padding:40px;
@media (max-width: 500px) {
    padding:30px;
    margin-top: 10px;
  }
`

const Container = styled.div`
  position: fixed;
 //padding: 40px;
 // width: 90%;
  max-width: 350px;
  background-color: white;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  height: 300px;
  display: flex;
  z-index: 71;
  box-shadow: 0 0 15px rgb(0 0 0 / 30%);
  flex-direction: column;
  border-radius: 20px;
  min-height:  550px;
 // position: relative;

  @media (max-width: 500px) {
    width: 90%;
  }
`
const Close = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  border: 0px;
  cursor: pointer;
  background-color: transparent;
  right: 6px;
  top: 6px;
`

const BoxDropItem = styled(Link)`
  text-decoration: none;
  margin-bottom: 8px;
  color: ${customTheme.colors.purple};
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 5px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    width: 100%;
    text-decoration: none;

  }

  span {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 5px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    width: 100%;

  }

  i {
    width: 30px;
    margin-right: 7px;
  }

  >svg {
    width: 22px;
    margin-right: 13px;
    margin-top: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;

    span {
      border: none;
      margin-bottom: -12px;
    }

    a {

      border: none;
      margin-bottom: -12px;
    }
  }
`
