/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:63dbe75d-24e2-4745-ae9a-28f6bcac230a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IUsllePFO",
    "aws_user_pools_web_client_id": "3olov1tgtgq98hopofo8l5n02q",
    "oauth": {
        "domain": "todasgroupprd.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://academy.todasgroup.com.br/",
        "redirectSignOut": "https://academy.todasgroup.com.br/",
        "responseType": "code"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;