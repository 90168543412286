import React, { useState } from 'react'
import { FormInput } from '../form'
import PropTypes from 'prop-types'
import TplGroup from './tpl_group'
import { Field } from 'formik'
import moment from 'moment'

const DatePiker = ({ name, title, type, placeholder, isRequired, tabIndex, disabled, ...el }) => {
  const [inputValue, setInputValue] = useState(moment(el.value || '').format('yyyy-MM-DD'))

  const handleChange = event => {
    setInputValue(event.target.value)
    if (el.setFieldValue) {
      el.setFieldValue(name, event.target.value)
    }
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired}>
      <Field
        type="date"
        value={inputValue}
        name={name}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        disabled={!!disabled}
        onChange={event => handleChange(event)}
        as={FormInput}
      />
    </TplGroup>
  )
}
export default DatePiker

DatePiker.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool
}
