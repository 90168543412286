import React from 'react'
import PropTypes from 'prop-types'
import { TplGroup } from './index'
import { Field } from 'formik'
import { FormSelect } from '../form'
import styled from 'styled-components'

const SelectOption = ({ name, title, isRequired, options = [], tabIndex, hidden, hasCoupon, ...el }) => {
  const handleChange = (event) => {
    el.setFieldValue(name, event.currentTarget.value)
  }

  if (hidden && options.length === 1) {
    return <Field type="hidden" name={name} value={options[0].value} />
  }

  return (
    <>
      <TplGroup name={name} title={title} isRequired={isRequired}>
        <Field
          as={FormSelect}
          name={name}
          tabIndex={tabIndex}
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <option key={`${name}-${index}`} value={option.value}>{option.text}</option>
          ))}
        </Field>
      </TplGroup>
      {hasCoupon &&
        <SmallStyled>Após expiração do cupom, será mantida a mesma quantidade de parcelas para o valor integral do plano adquirido.</SmallStyled>}
    </>
  )
}

SelectOption.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    text: PropTypes.string.isRequired
  })).isRequired,
  tabIndex: PropTypes.number,
  hidden: PropTypes.bool,
  hasCoupon: PropTypes.bool
}

export default SelectOption

const SmallStyled = styled.small`
  font-size: 12px;
  color: #2a3c4c;
  margin-top: 3px;
  line-height: 1.5;
  font-style: italic;
  display: block;
`
