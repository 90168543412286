import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Flex } from '../../../components/ui/components'
import { customTheme } from '../../../styles/theme'
import { useHistory } from 'react-router-dom'
import { makeChallenges } from '../../../../main/usecases/conquests/conquests-factory'
import HeaderListPage from '../../../components/ui/layout/header-list-page/header-list-page'
import { GiftIcon } from '../../../assets/icons/gift'
import { QuestionIcon } from '../../../assets/icons/question'
import { makeLoadContentInternal } from '../../../../main/usecases/contents/load-content-factory'
import { useTranslation } from 'react-i18next'

const RewardItem = ({ content, value, index, isLast }) => {
  const [noContentModal, setNoContentModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()

  const TRANSLATE = {
    Início: `${t('conquest_internal.start')}`,
    'Desbloquear novos selos e prêmios': `${t('conquest_internal.unblock')}`
  }

  const verifyAward = async (content) => {
    setLoading(true)
    if (!content) {
      const res = await makeLoadContentInternal().loadInternal(content['@id'].split('/')[content['@id'].split('/').length - 1])
      const redirectTo = res.folder['@type'] === 'Skill'
        ? `/habilidades/${res?.folder?.id}/${res.slug}`
        : `/${res?.folder?.slug}/${res?.slug}`
      history.push(redirectTo)
    } else {
      setNoContentModal(true)
    }
    setLoading(false)
  }

  return (
    <>
      <RewardItemStyled className={(value < content?.quantity || isLast) && 'disable'}>
        {index !== 0 &&
          <Line
            position="start"
            fill={value >= content.quantity}
          />}

        {index === 0 || isLast
          ? <Dot fill={index === 0} />
          : (
            <IconBox
              onClick={() => {
                if (value >= content.quantity) {
                  verifyAward(content.content)
                }
              }}
            >
              {value >= content.quantity ? <GiftIcon title={`${t('conquest_internal.gift')}`} style={{ cursor: loading ? 'no-drop' : 'pointer' }} /> : <QuestionIcon />}
            </IconBox>)}

        <img src={content?.url || content?.badge?.url} />

        {index === 0
          ? <h3>{TRANSLATE[content?.label]}</h3>
          : <h3>{content?.quantity || TRANSLATE[content?.label]}</h3>}

        {!isLast && index !== 0 && !(value < content?.quantity) &&
          <ShareButton
            onClick={() => {
              const a = document.createElement('a')
              a.style.display = 'none'
              a.href = content?.shareImage?.url || ''
              document.body.appendChild(a)
              a.click()
            }}
          >
            {t('conquest_internal.shared')}
          </ShareButton>}

        {!isLast &&
          <Line
            position="end"
            fill={value >= content.quantity}
          />}
      </RewardItemStyled>

      {noContentModal &&
        <ModalShare>
          <ModalBg />
          <ModalContent>
            <CloseButton onClick={() => setNoContentModal(false)}>+
            </CloseButton>
            <Flex justifyContent="space-around" my="20px" mx="auto">
              <h2>Prêmio indisponivel</h2>
            </Flex>
          </ModalContent>
        </ModalShare>}
    </>
  )
}

RewardItem.propTypes = {
  content: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
  isLast: PropTypes.boolean
}

const ConquestInterna = () => {
  const history = useHistory()
  const [newList, setNewList] = useState([])
  const [content, setContent] = useState([])
  const [award, setAward] = useState({})
  const { t } = useTranslation()

  const TRANSLATE = {
    'Assistir Conteúdos': `${t('conquest_main.obj')}`,
    'Fazer Network': `${t('conquest_main.obj_02')}`
  }

  const TRANSLATE_DESC = {
    '<p>Sua evolu&ccedil;&atilde;o constante &eacute; muito importante para o TODAS GROUP. A cada selo especial conquistado, voc&ecirc; ganha um pr&ecirc;mio e fortalece ainda mais toda nossa comunidade de usu&aacute;rias.</p>': `${t('conquest_internal.title_desc')}`
  }

  const TRANSLATE_AWARD = {
    '<p>Aula 01 - Auto estima para posicionamento</p>': `${t('conquest_internal.award_01')}`,
    '<p>Clique aqui para acessar o seu pr&ecirc;mio</p>': `${t('conquest_internal.award_02')}`
  }

  useEffect(async () => {
    try {
      const challenges = await makeChallenges().load()
      const atualConquest = challenges['hydra:member'].find(item => item.slug === history.location.pathname.replace('/conquistas/', ''))
      setContent(atualConquest)
      setAward(atualConquest.awards.find(i => i.quantity <= atualConquest?.currentCustomerProgress))
      setNewList([
        {
          ...atualConquest.startBadge,
          label: atualConquest.startBadgeLabel,
          quantity: 0
        },
        ...atualConquest.awards,
        {
          ...atualConquest.lastBadge,
          label: atualConquest.lastBadgeLabel
        }
      ])
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <Container>
      <HeaderListPage title={TRANSLATE[content.title]} setQuery={false} />
      <Description dangerouslySetInnerHTML={{ __html: TRANSLATE_DESC[content.description] ? TRANSLATE_DESC[content.description] : content.description }} />
      <ActualConquest>
        <BoxLeft>
          <h2>{t('conquest_internal.conquest')}</h2>
          <p>
            {content.currentCustomerProgress
              ? <><span>{content.currentCustomerProgress}</span> {t('conquest_internal.content_watch')}</>
              : `${t('conquest_internal.progress')}`}
          </p>
        </BoxLeft>
        <BoxRight>
          <h2>{t('conquest_internal.gift_name')}</h2>
          <span dangerouslySetInnerHTML={{ __html: TRANSLATE_AWARD[award?.description] ? TRANSLATE_AWARD[award?.description] : award?.description }} />
        </BoxRight>
      </ActualConquest>

      <Flex mt={130} justifyContent="space-between">
        {newList.map((item, index) => {
          return (
            <RewardItem
              key={index}
              content={item}
              value={content.currentCustomerProgress}
              index={index}
              isLast={index === newList.length - 1}
            />
          )
        })}
      </Flex>
    </Container>
  )
}

export default ConquestInterna

const Description = styled.p`
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #828282;
`

const ActualConquest = styled.div`
  display: flex;
  margin: 50px 0;
`

const BoxLeft = styled.div`
  border: 1px ${customTheme.colors.purple} solid;
  border-radius: 5px;
  padding: 10px;
  width: 25%;
  margin-right: 20px;

  h2 {
    font-size: 16px;
    font-wight: bold;
    margin-bottom: 5px;
    color: ${customTheme.colors.purple};
  }

  p {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: auto;
    color: ${customTheme.colors.purple};

    span {
      font-size: 50px;
      font-weight: bold;
      padding: 10px;
    }
  }
`

const BoxRight = styled.div`
  border: 1px ${customTheme.colors.purple} solid;
  border-radius: 5px;
  padding: 10px;
  width: 50%;

  h2 {
    font-size: 16px;
    font-wight: bold;
    margin-bottom: 5px;
    color: ${customTheme.colors.purple};
  }
`

const RewardItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  position: relative;

  h3 {
    color: ${customTheme.colors.purple};
    text-align: center;
    font-size: 14px;
  }

  h4 {
    color: ${customTheme.colors.gray[300]};
    text-align: center;
    font-size: 14px;
  }

  span {
    color: ${customTheme.colors.purple};
    text-align: center;
    font-size: 14px;
    width: 70%;
    display: flex;
    line-height: 1;
  }

  img {
    height: 70px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  &.disable {
    img {
      filter: contrast(.5) grayscale(1);
    }

    h3 {
      color: ${customTheme.colors.gray[200]};
    }

    span {
      color: ${customTheme.colors.gray[200]};
    }
  }
`

const Line = styled.div`
  position: absolute;
  height: 2px;
  width: 40%;
  background: ${props => props.fill ? '#25D366' : '#D8D8D8'};
  top: 0;
  left: ${props => props.position === 'start' && 0};
  right: ${props => props.position === 'end' && 0};
`

const Dot = styled.div`
  background: ${props => props.fill ? '#25D366' : '#D8D8D8'};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: -5px 0 25px;
`

const IconBox = styled.div`
  height: 30px;
  margin: -15px 0 15px;
  
  i {
    cursor: pointer;
    height: 90%;
    display: flex;
    align-items: center;
  }
`

const ShareButton = styled.a`
  display: flex;
  background: ${customTheme.colors.purple};
  color: #fff;
  font-size: 12px;
  padding: 3px 8px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
`

const ModalShare = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 99999;
`

const ModalBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #00000059;
`

const ModalContent = styled.div`
  width: 300px;
  max-width: 90%;
  background: #fff;
  padding: 15px;
  position: relative;
  border-radius: 5px;
`

const CloseButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 5px;
  line-height: 1;
  font-size: 30px;
  font-weight: bold;
  color: ${customTheme.colors.purple};
  cursor: pointer;
`
