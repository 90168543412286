import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const GrayVirgula = (props) => {
  return (
    <GrayVirgulaStyled {...props}>
      <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5409_21964)">
          <path d="M6.90115 0C8.28387 0 9.32091 0.162791 10.3579 0.651163C11.395 1.13953 12.0863 1.7907 12.6049 2.60465C13.1234 3.25581 13.6419 4.23256 13.9876 5.37209C14.1604 6.51163 14.3333 7.81395 14.3333 8.95349C14.3333 11.3953 13.6419 13.8372 12.2592 15.9535C10.8765 18.0698 8.80239 19.8605 5.86412 21L5.17276 19.5349C6.72831 18.8837 7.93819 18.0698 9.14807 16.7674C10.3579 15.6279 11.0493 14.3256 11.2221 13.0233C11.5678 11.8837 11.5678 10.907 11.2221 9.93023C10.1851 11.0698 8.62955 11.5581 6.72831 11.5581C4.82708 11.5581 3.27152 11.0698 2.23449 9.93023C0.85177 9.11628 0.333252 7.65116 0.333252 5.86047C0.333252 4.06977 1.02461 2.60465 2.23449 1.62791C3.44436 0.488372 4.99992 0 6.90115 0Z" fill="#9D9D9D" />
        </g>
        <defs>
          <clipPath id="clip0_5409_21964">
            <rect width="14" height="21" fill="white" transform="translate(0.333252)" />
          </clipPath>
        </defs>
      </svg>

    </GrayVirgulaStyled>
  )
}

const GrayVirgulaStyled = styled(IconStyled)``
